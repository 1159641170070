import BackofficeService from "@/api/services/backoffice";

const initialState = {
  availableFilters: {
    status: [],
    organizations: [],
    stores: [],
  },
  selectedFilters: {
    status: [],
    organizations: [],
    stores: [],
  },
  claims: [],
  totalCount: null,
  totalPages: null,
  keywords: "",
};

export default {
  namespaced: true,

  state: initialState,

  getters: {
    claims: (state) => {
      return state.claims;
    },

    totalCount: (state) => {
      return state.totalCount;
    },

    totalPages: (state) => {
      return state.totalPages;
    },
  },

  mutations: {
    setClaims(state, { claims, totalCount, totalPages }) {
      state.claims = claims;
      state.totalCount = totalCount;
      state.totalPages = totalPages;
    },

    setFilter(state, { filterName, filterOptions }) {
      state.selectedFilters[filterName] = filterOptions;
    },

    setKeywords(state, keywords) {
      state.keywords = keywords;
    },

    setAvailableFilters(state, { status, organizations, stores }) {
      state.availableFilters.status = status;
      state.availableFilters.stores = stores;
      state.availableFilters.organizations = organizations;
    },
  },

  actions: {
    async getClaims({ state, commit }, { page }) {
      const response = await BackofficeService.getClaims({
        page: page,
        organizations: state.selectedFilters.organizations,
        stores: state.selectedFilters.stores,
        status: state.selectedFilters.status,
        keywords: state.keywords,
      });
      if (!response?.success) return response;

      commit("setClaims", {
        claims: response.data.claims,
        totalCount: response.data.page.totalCount,
        totalPages: response.data.page.totalPages,
      });

      commit("setAvailableFilters", {
        status: response.data.filters.status,
        organizations: response.data.filters.organizations,
        stores: response.data.filters.stores,
      });
      return response;
    },

    updateSearch({ commit, dispatch }, { searchString, page }) {
      commit("setKeywords", searchString);
      return dispatch("getClaims", {
        page: page,
      });
    },

    updateFilter({ commit, dispatch }, { filterName, filterOptions, page }) {
      commit("setFilter", { filterName, filterOptions });
      return dispatch("getClaims", {
        page: page,
      });
    },
  },
};
