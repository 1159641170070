<template>
  <Listbox as="div" v-model="selected">
    <div class="relative">
      <ListboxButton
        class="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm"
      >
        <span class="block truncate">{{ selected.firstName }}</span>
        <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </span>
      </ListboxButton>

      <transition
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          class="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        >
          <ListboxOption
            as="template"
            v-for="salesFieldManager in augmentedSalesFieldManagers"
            :key="salesFieldManager.id"
            :value="salesFieldManager"
            v-slot="{ active, selected }"
          >
            <li
              :class="[
                active ? 'text-white bg-primary' : 'text-gray-900',
                'relative cursor-default select-none py-2 pl-3 pr-9',
              ]"
            >
              <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                {{ salesFieldManager.firstName }}
              </span>
              <span
                v-if="selected"
                :class="[
                  active ? 'text-white' : 'text-primary-dark',
                  'absolute inset-y-0 right-0 flex items-center pr-4',
                ]"
              >
                <CheckIcon class="h-5 w-5" aria-hidden="true" />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script>
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/vue";
import { CheckIcon, ChevronDownIcon } from "@heroicons/vue/outline";

const defaultSelectedValue = { id: null, firstName: "Aucun" };

export default {
  components: {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    ChevronDownIcon,
  },
  props: ["salesFieldManagers", "selectedSalesFieldManager"],
  data() {
    return {
      selected: defaultSelectedValue,
    };
  },
  computed: {
    augmentedSalesFieldManagers() {
      return [defaultSelectedValue, ...this.salesFieldManagers];
    },
  },
  watch: {
    selected(newValue) {
      this.$emit("update:selectedSalesFieldManager", newValue.id);
    },
  },
  mounted() {
    if (this.selectedSalesFieldManager) {
      const manager = this.salesFieldManagers?.find((manager) => manager.id === this.selectedSalesFieldManager.id);
      this.selected = manager || defaultSelectedValue;
    }
  },
};
</script>
