<template>
  <div class="flex items-center justify-between">
    <PageHeader title="Plan sales" />
  </div>
</template>
<script>
import PageHeader from "@/components/utils/PageHeader.vue";

export default {
  components: {
    PageHeader,
  },
};
</script>
