<template>
  <div>
    <div class="grid grid-cols-2 gap-4 my-2">
      <label class="font-bold text-gray-900">Assureur</label>
      <label class="font-bold text-gray-900">Période</label>
    </div>
    <div class="grid grid-cols-2 gap-4 mt-2" v-for="insurer in insurers" :key="insurer.id">
      <span>
        {{ insurer.name }}
      </span>
      <MonthlyDateFilterSelector
        :key="insurer.id"
        placeholder="Sélectionner une période"
        @newDateSelected="(startDate, endDate) => updateDateFilters(insurer, startDate, endDate)"
      />
    </div>
    <div class="mt-4 flex justify-end">
      <Loading v-if="isLoading" />
      <GenericButton v-else @click="submit">Exporter</GenericButton>
    </div>
  </div>
</template>
<script>
import GenericButton from "@/components/utils/GenericButton.vue";
import { Loading } from "@estaly/ui";
import MonthlyDateFilterSelector from "@/components/menu/MonthlyDateFilterSelector.vue";
import BackofficeService from "@/api/services/backoffice";
import { toSnakeCase } from "@estaly/ui/src/utils/data_formatter";
import { mapActions } from "vuex";

export default {
  components: {
    GenericButton,
    Loading,
    MonthlyDateFilterSelector,
  },

  emits: ["success"],

  data: () => {
    return {
      isLoading: false,
      insurers: [],
      payload: {},
    };
  },

  created() {
    this.fetchInsurers();
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    async fetchInsurers() {
      this.isLoading = true;
      const response = await BackofficeService.getInsurers();
      if (response?.success) this.insurers = response.data.insurers;
      this.isLoading = false;
    },

    async updateDateFilters(insurer, startDate, endDate) {
      this.payload[insurer.id] = { startDate, endDate };
    },

    async submit() {
      this.isLoading = true;
      const periods = Object.entries(this.payload).map(([insurerId, { startDate, endDate }]) =>
        toSnakeCase({
          insurer: insurerId,
          startDate,
          endDate,
        }),
      );
      const { success } = await BackofficeService.downloadQontoBulkTransferCSV({ periods });
      if (success) this.$emit("success");
      this.isLoading = false;
    },
  },
};
</script>
