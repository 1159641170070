<template>
  <div>
    <Selector
      id="challenge-selector"
      name="challenge"
      label="Sélectionnez un challenge"
      v-model="selectedChallenge"
      :options="challengeOptions"
      required
    />

    <Selector
      v-if="stores.length"
      id="store-selector"
      name="store"
      label="Sélectionnez un magasin"
      v-model="selectedStore"
      :options="storeOptions"
      required
    />

    <div class="mt-4 space-y-4" v-if="selectedStore">
      <Table :columns="storeTableColumns" :data="storeTableData" />
      <Table :columns="sellerTableColumns" :data="sellerTableData" />
    </div>
  </div>
</template>

<script>
import { Selector, Table } from "@estaly/ui";
import BackofficeService from "@/api/services/backoffice";
import { toCamelCase } from "@estaly/ui/src/utils/data_formatter";

export default {
  components: {
    Selector,
    Table,
  },

  props: {
    challenges: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  data() {
    return {
      selectedChallenge: "",
      stores: [],
      store: null,
      selectedStore: "",
      sellers: [],
    };
  },

  computed: {
    challengeOptions() {
      return this.challenges.map((challenge) => ({
        label: `${challenge.id} ${challenge.title || ""}`,
        value: challenge.id,
      }));
    },
    storeOptions() {
      return this.stores.map((store) => ({
        label: store.salesChannel,
        value: store.id,
      }));
    },

    challengeType() {
      return this.challenges.find((challenge) => challenge.id == this.selectedChallengeId)?.type;
    },

    storeTableColumns() {
      return [
        { field: "salesChannel", label: "Magasin", bold: true },
        { field: "email", label: "Email" },
        { field: "score", label: "Score", numeric: true },
        { field: "rewardAmount", label: "Gain", numeric: true, price: true },
      ];
    },

    storeTableData() {
      return this.store
        ? [
            {
              salesChannel: this.store.salesChannel,
              email: this.store.email,
              score: this.store.storeScore,
              rewardAmount: this.store.storeRewardAmount,
            },
          ]
        : [];
    },

    sellerTableColumns() {
      return [
        { field: "name", label: "Vendeur", bold: true },
        { field: "email", label: "Email" },
        { field: "score", label: "Score", numeric: true },
        { field: "rewardAmount", label: "Gain", numeric: true, price: true },
      ];
    },

    sellerTableData() {
      return this.sellers.map((seller) => ({
        name: seller.name,
        email: seller.email,
        score: seller.score,
        rewardAmount: seller.rewardAmount,
      }));
    },
  },

  watch: {
    async selectedChallenge(newChallengeId) {
      if (newChallengeId) {
        await this.retrieveStores();
      } else {
        this.stores = [];
      }
    },

    async selectedStore(newStoreId) {
      if (newStoreId) {
        await this.retrieveChallengePerformances();
      }
    },
  },

  methods: {
    async retrieveStores() {
      const response = await BackofficeService.getStores({ challenge_id: this.selectedChallenge });
      if (response?.success) this.stores = response.data.stores;
    },
    async retrieveChallengePerformances() {
      const response = await BackofficeService.getChallengesAndSellersPerformance(
        this.selectedStore,
        this.selectedChallenge,
      );
      if (response?.success) {
        const data = toCamelCase(response.data);
        this.store = data.performance.storePerformance;
        this.sellers = data.performance.sellers;
      }
    },
  },
};
</script>
