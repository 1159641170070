<template>
  <TabsNavigation :tabs="tabs" />
</template>

<script>
import { TabsNavigation } from "@estaly/ui";

export default {
  components: { TabsNavigation },

  data: () => ({
    tabs: [
      { name: "analytics_general", path: "/analytics/general", displayName: "Vue d'ensemble" },
      { name: "analytics_retails", path: "/analytics/retail", displayName: "Suivi des ventes" },
    ],
  }),
};
</script>
