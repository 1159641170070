<template>
  <div>
    <Header />
    <Selector
      id="organization"
      label="Organisation"
      name="organization"
      class="mb-8"
      v-model="selectedOrganizationId"
      :options="organizationOptions"
    />

    <GeneralAnalytics
      :organization-id="selectedOrganizationId"
      :analytics="analytics"
      :available-stores="availableStores"
      @filtersUpdated="analyticsFiltersUpdated"
      @viewSellersRanking="openSellersRankingModal = true"
    />

    <SellersRankingModal
      :open="openSellersRankingModal"
      :data="sellersRankingData"
      :total-count="sellersRankingTotalCount"
      :total-pages="sellersRankingTotalPages"
      :current-page="sellersRankingCurrentPage"
      @closeModal="openSellersRankingModal = false"
      @changePage="(newPage) => (sellersRankingCurrentPage = newPage)"
      @refreshData="getSellersRanking"
    />
  </div>
</template>

<script>
import Header from "@/components/analytics/Header.vue";
import { GeneralAnalytics, Selector, SellersRankingModal } from "@estaly/ui";
import { mapActions, mapState } from "vuex";
import BackofficeService from "@/api/services/backoffice";
import { toCamelCase } from "@/utils/utils";

export default {
  components: {
    Header,
    GeneralAnalytics,
    SellersRankingModal,
    Selector,
  },

  data() {
    return {
      selectedOrganizationId: "all",
      analytics: {},
      availableStores: [],
      currentFilters: {},
      openSellersRankingModal: false,
      sellersRankingData: [],
      sellersRankingTotalCount: null,
      sellersRankingTotalPages: null,
      sellersRankingCurrentPage: null,
    };
  },

  computed: {
    ...mapState("backoffice", ["organizations"]),

    organizationOptions() {
      const options = [{ value: "all", label: "Toutes les organisations" }];
      return options.concat(
        this.organizations.map((organization) => ({
          value: organization.id,
          label: organization.name,
        })),
      );
    },
  },

  mounted() {
    this.getOrganizations();
  },

  watch: {
    openSellersRankingModal(newValue) {
      if (newValue) {
        this.sellersRankingCurrentPage = 1;
      }
    },
    sellersRankingCurrentPage() {
      this.getSellersRanking();
    },
  },

  methods: {
    ...mapActions("backoffice", ["getOrganizations"]),

    analyticsFiltersUpdated(filters) {
      this.currentFilters = filters;
      const organizationId = this.selectedOrganizationId === "all" ? null : this.selectedOrganizationId;
      const apiParams = { organizationId: organizationId, ...filters };
      this.fetchAnalytics(apiParams);
    },

    async fetchAnalytics(apiParams) {
      const response = await BackofficeService.getGeneralAnalytics(apiParams);
      if (!response?.success) return;

      const formattedResponse = toCamelCase(response.data);

      this.analytics = formattedResponse.analytics;
      this.availableStores = formattedResponse.availableStores;
    },

    async getSellersRanking() {
      const params = {
        page: this.sellersRankingCurrentPage,
        startDate: this.currentFilters ? this.currentFilters.startDate : null,
        endDate: this.currentFilters ? this.currentFilters.endDate : null,
      };

      const response = await BackofficeService.getSellersRanking(params);

      if (response?.success) {
        const formattedResponse = toCamelCase(response.data);
        this.sellersRankingData = formattedResponse.sellers;
        this.sellersRankingTotalCount = formattedResponse.page.totalCount;
        this.sellersRankingTotalPages = formattedResponse.page.totalPages;
      }
    },
  },
};
</script>
