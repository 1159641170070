<template>
  <div class="mt-5">
    <FormField
      id="product_state"
      name="product_state_input"
      v-model="productState"
      label="Veuillez décrire brièvement l'état du produit"
      required
      type="text"
      field-class="block w-full rounded-md border-0 py-1.5 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
    />
    <FormField
      class="mt-2"
      id="file_input"
      v-model="file"
      required
      type="file"
      label="Veuillez charger le devis"
      field-class="block w-full text-sm text-gray-700 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
      name="quotation_upload"
    />
    <CostItems class="mt-6" @updatedItems="(items) => (costItems = items)" />
  </div>
</template>
<script>
import CostItems from "@/components/claim/details/information/action/cost_items/CostItems.vue";
import { FormField } from "@estaly/ui";
export default {
  components: { FormField, CostItems },
  data: () => {
    return {
      costItems: null,
      productState: null,
      file: null,
    };
  },
  watch: {
    costItems() {
      this.emitData();
    },
    productState() {
      this.emitData();
    },
    file() {
      this.emitData();
    },
  },
  methods: {
    emitData() {
      this.$emit("update", this.productState, this.file, this.costItems);
    },
  },
};
</script>
