<template>
  <div>
    <PageHeader title="Leads" />
    <div class="mb-6 flex flex-col sm:w-1/4 sm:flex-row w-full">
      <DateRangePicker placeholder="Sélectionner une période" v-model="dateRange" use-query-params />
    </div>
    <SearchBar
      class="mb-6"
      :placeholder="$t('Rechercher un lead par nom, prénom, email ou numéro de téléphone')"
      :current-page="currentPage"
      :available-filters="availableFiltersWithOptions"
      :selected-filters="currentFilteringData"
      :initial-keywords="currentFilteringData.keywords"
      @updateFilterSearch="updateCurrentFilteringData"
      @updateKeywordSearch="updateKeywordSearch"
    />
    <Table :columns="columns" :data="formattedData" is-updatable @update="openLeadModal" />
    <Pagination
      :currentPage="currentPage"
      :totalPages="totalPages"
      :totalCount="totalCount"
      @changePage="handleChangePage"
    />
  </div>
  <LeadModal
    :open="openModal"
    :lead="currentLead"
    :discounts="discounts"
    @close="openModal = false"
    @getLeads="getLeads"
  />
</template>

<script>
import PageHeader from "@/components/utils/PageHeader.vue";
import { DateRangePicker, Pagination } from "@estaly/ui";
import SearchBar from "@/components/menu/SearchBar.vue";
import { Table } from "@estaly/ui";
import LeadModal from "@/views/leads/LeadModal.vue";

import BackofficeService from "@/api/services/backoffice";
import { toCamelCase } from "@estaly/ui/src/utils/data_formatter";
import { mapState } from "vuex";

export default {
  components: { Pagination, PageHeader, Table, DateRangePicker, SearchBar, LeadModal },

  data() {
    return {
      leads: [],
      currentPage: 1,
      totalPages: null,
      totalCount: null,
      discounts: [],
      filters: {
        callback_date_status: [],
        status: [],
        type: [],
        store: [],
      },
      currentFilteringData: {
        callbackDateStatus: [],
        keywords: null,
        startDate: null,
        endDate: null,
        status: [],
        type: [],
        store: [],
      },
      columns: [
        { label: "Date de création", field: "creationDate" },
        { label: "Magasin", field: "storeSalesChannel" },
        { label: "Email", field: "email" },
        { label: "Type", field: "type" },
        {
          label: "Statut",
          field: "status",
          class: {
            live: "p-1 rounded-lg bg-yellow-50 text-yellow-600",
            expired: "p-1 rounded-lg bg-gray-50 text-gray-600",
            converted: "p-1 rounded-lg bg-green-50 text-green-600",
            waiting_for_confirmation: "p-1 rounded-lg bg-blue-50 text-blue-600",
          },
        },
      ],
      openModal: false,
      currentLead: null,
    };
  },

  computed: {
    ...mapState("backoffice", ["isLoading"]),

    dateRange: {
      get() {
        return [this.currentFilteringData.startDate, this.currentFilteringData.endDate];
      },
      set(value) {
        if (value && value.length === 2) {
          this.updateDateFilters(value[0], value[1]);
        } else {
          this.updateDateFilters(null, null);
        }
      },
    },

    availableFiltersWithOptions() {
      return Object.fromEntries(
        Object.entries(this.filters).filter(([filterKey, filterOptions]) => filterKey && filterOptions?.length > 1),
      );
    },

    formattedData() {
      return this.leads.map((lead) => ({
        id: lead.id,
        creationDate: lead.purchaseDate,
        storeSalesChannel: lead.store.salesChannel,
        email: lead.customer.email,
        name: lead.customer.firstName ? `${lead.customer.firstName} ${lead.customer.lastName}` : null,
        phone: lead.customer.phone,
        productName: lead.variant.title,
        productPrice: lead.variant.price,
        expirationDate: lead.expirationDate,
        subscriptionLink: lead.subscriptionLink,
        type: lead.type,
        status: lead.status,
        callbackDate: lead.callbackDate,
        objections: lead.objections,
        comments: lead.comments,
        discount: lead.discount.title,
        seller: lead.seller.name,
      }));
    },
  },

  created() {
    this.getLeads();
  },

  methods: {
    openLeadModal(lead) {
      this.currentLead = lead;
      this.openModal = true;
    },
    updateKeywordSearch(keywords) {
      this.currentFilteringData["keywords"] = keywords;
      this.currentPage = 1;
      this.getLeads();
    },
    updateDateFilters(startDate, endDate) {
      this.currentFilteringData["startDate"] = startDate;
      this.currentFilteringData["endDate"] = endDate;
      this.handleChangePage(1);
    },
    updateCurrentFilteringData(filterName, filterOptions) {
      this.currentFilteringData[filterName] = filterOptions;
      this.handleChangePage(1);
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.getLeads();
    },
    async getLeads() {
      const params = {
        page: this.currentPage,
        includes: ["store", "seller", "discount"],
        ...this.currentFilteringData,
      };
      const response = await BackofficeService.getLeads(params);

      if (!response?.success) return;

      const data = toCamelCase(response.data);
      this.filters = data.filters;
      this.leads = data.leads;
      this.totalCount = data.page.totalCount;
      this.totalPages = data.page.totalPages;
      this.discounts = [{ Id: null, title: "Aucune" }].concat(data.discounts);
    },
  },
};
</script>
