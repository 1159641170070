<template>
  <div>
    <PageHeader title="Declared Claims" />
    <SearchBar
      :title="$t('Claim filters')"
      :placeholder="$t('SearchClaims')"
      :current-page="currentPage"
      :available-filters="availableFilters"
      :selected-filters="selectedFilters"
      :initial-keywords="keywords"
      @updateFilterSearch="updateFilterSearch"
      @updateKeywordSearch="updateKeywordSearch"
    />
    <PaginatedClaimList
      class="mt-10"
      :total-count="totalCount"
      :total-pages="totalPages"
      :current-page="currentPage"
      :claims="claims"
      @changePage="handleChangePage"
    />
  </div>
</template>

<script>
import PageHeader from "@/components/utils/PageHeader.vue";
import PaginatedClaimList from "@/components/claim/list/PaginatedClaimList.vue";
import { mapActions, mapState } from "vuex";
import SearchBar from "@/components/menu/SearchBar.vue";

export default {
  components: {
    PageHeader,
    SearchBar,
    PaginatedClaimList,
  },

  computed: {
    ...mapState("claim_list", [
      "claims",
      "totalPages",
      "totalCount",
      "availableFilters",
      "selectedFilters",
      "keywords",
    ]),
  },

  data() {
    return {
      currentPage: 1,
    };
  },

  methods: {
    ...mapActions("claim_list", ["getClaims", "updateFilter", "updateSearch"]),
    ...mapActions("notifications", ["notify"]),

    handleChangePage: function (page) {
      this.updatePage(page);
    },

    retrieveClaims: async function () {
      await this.getClaims({ page: this.currentPage });
    },

    updateFilterSearch: function (filterName, filterOptions) {
      this.updatePage(1, false);
      this.updateFilter({
        filterName: filterName,
        filterOptions: filterOptions,
        page: this.currentPage,
      });
    },

    updateKeywordSearch: function (keyword) {
      this.updatePage(1, false);
      this.updateSearch({
        searchString: keyword,
        page: this.currentPage,
      });
    },

    updatePage: function (newPage, refreshClaims = true) {
      this.currentPage = newPage;
      history.pushState({}, "", `?page=${newPage}`);
      if (refreshClaims) {
        this.retrieveClaims();
      }
    },
  },

  created() {
    this.currentPage = parseInt(this.$route.query.page) || 1;
    this.retrieveClaims();
  },
};
</script>
