<template>
  <div>
    <Header />
    <InvoicesDownloadModal
      :open="openInvoicesDownloadModal"
      @close="openInvoicesDownloadModal = false"
      @sendInvoicesReport="sendInvoicesReport"
    />
    <Modal :open="bulkTransferFormModalOpen" @close="bulkTransferFormModalOpen = false">
      <div class="p-4">
        <h2 class="text-lg font-bold">Exporter le fichier de virements aux marchands</h2>
        <p class="text-sm text-gray-500">Sélectionnez les données à exporter</p>
        <BulkTransferForm @success="bulkTransferFormModalOpen = false" />
      </div>
    </Modal>
    <InvoicesSummary :bill-amount="billAmount" :statement-amount="statementAmount" />
    <div class="flex justify-between">
      <div class="flex gap-x-6 items-center">
        <MonthlyDateFilterSelector
          class="w-1/4"
          placeholder="Sélectionner une période"
          @newDateSelected="updateDateFilters"
        />
        <SearchBarFilter
          v-for="(filterCategory, filtersName, filterIndex) in availableFiltersWithOptions"
          :key="filterIndex"
          :filterName="filtersName"
          :filterIdx="filterIndex"
          :current-page="currentPage"
          :available-filters="availableFilters"
          :selected-filters="selectedFilters"
          @updateFilterSearch="updateSelectedFilters"
        />
      </div>
      <div class="flex">
        <SecondaryButton v-if="selectedRows.length > 0" @click="markInvoicesAsPaid">Marquer comme payé</SecondaryButton>
        <div v-else>
          <SecondaryButton class="mr-2" @click="openInvoicesDownloadModal = true">
            {{ $t("Download") }}
          </SecondaryButton>
          <SecondaryButton @click="bulkTransferFormModalOpen = true">Exporter les virements</SecondaryButton>
        </div>
      </div>
    </div>
    <div class="mt-5">
      <Table
        :columns="columns"
        :data="invoices"
        :isDownloadable="true"
        :isSelectable="true"
        @download="download"
        @newSelectedRows="updateSelectedRows"
        :selectableRowFilter="selectableRowFilter"
      >
        <template #paymentStatus="{ row }">
          <FieldSpan :style-to-add="paymentStatusFieldDisplayStyle(row.paymentStatus)" :value="$t(row.paymentStatus)" />
        </template>
        <template #amount="{ row }">
          {{ formatPrice(row.amount) }}
        </template>
      </Table>
      <Pagination
        :currentPage="currentPage"
        :totalPages="totalPages"
        :totalCount="totalCount"
        @changePage="handleChangePage"
      />
    </div>
  </div>
</template>

<script>
import BackofficeService from "@/api/services/backoffice";
import { mapActions, mapState } from "vuex";
import InvoicesSummary from "@/components/billing/invoices/InvoicesSummary.vue";
import MonthlyDateFilterSelector from "@/components/menu/MonthlyDateFilterSelector.vue";
import { SearchBarFilter } from "@estaly/ui";
import InvoicesDownloadModal from "@/components/billing/invoices/InvoicesDownloadModal.vue";
import { formatPrice } from "@estaly/ui/src/utils/price_formatter";
import { Modal, Pagination, Table, SecondaryButton } from "@estaly/ui";
import BulkTransferForm from "@/components/billing/invoices/BulkTransferForm.vue";
import Header from "@/components/billing/Header.vue";
import { toCamelCase } from "@/utils/utils";
import FieldSpan from "@/components/utils/FieldSpan.vue";

export default {
  components: {
    FieldSpan,
    Table,
    Header,
    Pagination,
    SearchBarFilter,
    MonthlyDateFilterSelector,
    SecondaryButton,
    InvoicesSummary,
    InvoicesDownloadModal,
    BulkTransferForm,
    Modal,
  },

  computed: {
    ...mapState("backoffice", ["selectedOrganizationId"]),

    availableFiltersWithOptions() {
      return Object.fromEntries(
        Object.entries(this.availableFilters).filter(
          ([filterKey, filterOptions]) => filterKey && filterOptions?.length > 1,
        ),
      );
    },
  },

  data() {
    return {
      invoices: [],
      availableFilters: {
        organizations: [],
        stores: [],
        paymentStatuses: [],
        transactionTypes: [],
      },
      billAmount: null,
      statementAmount: null,
      totalNumberOfInvoices: 0,
      currentPage: 1,
      selectedRows: [],
      selectedFilters: {
        organizations: [],
        stores: [],
        paymentStatuses: [],
        transactionTypes: [],
        paymentStartDate: null,
        paymentEndDate: null,
      },
      totalCount: null,
      totalPages: null,
      exportingInvoices: false,
      openInvoicesDownloadModal: false,
      bulkTransferFormModalOpen: false,
      columns: [
        { label: "Date de versement", field: "paymentDate" },
        { label: "Entité", field: "salesChannel" },
        { label: "Type", field: "transactionType" },
        { label: "Libellé", field: "customId" },
        { label: "Montant", slot: "amount", field: "amount" },
        { label: "Statut paiement", slot: "paymentStatus", field: "status" },
      ],
    };
  },

  mounted() {
    this.retrieveInvoices();
  },

  methods: {
    ...mapActions("notifications", ["notify"]),
    formatPrice,

    handleChangePage(page) {
      this.currentPage = page;
      this.retrieveInvoices();
    },

    updateSelectedFilters(filterName, filterOptions) {
      this.selectedFilters[filterName] = filterOptions;
      this.handleChangePage(1);
    },

    updateDateFilters(startDate, endDate) {
      this.selectedFilters["paymentStartDate"] = startDate;
      this.selectedFilters["paymentEndDate"] = endDate;
      this.handleChangePage(1);
    },

    async retrieveInvoices() {
      const response = await BackofficeService.getInvoices(this.currentPage, this.selectedFilters);
      if (!response?.success) return;

      const formattedResponse = toCamelCase(response.data);

      this.invoices = formattedResponse.invoices;
      this.availableFilters = formattedResponse.filters;
      this.billAmount = formattedResponse.billAmount;
      this.statementAmount = formattedResponse.statementAmount;
      this.totalCount = formattedResponse.page.totalCount;
      this.totalPages = formattedResponse.page.totalPages;
    },

    async markInvoicesAsPaid() {
      const invoiceIds = this.selectedRows.map((row) => row.id);
      if (invoiceIds.length === 0) return;
      const response = await BackofficeService.markInvoicesAsPaid(invoiceIds);
      if (response?.success) {
        await this.retrieveInvoices();
      }
    },

    async sendInvoicesReport(email) {
      this.openInvoicesDownloadModal = false;
      const filters = {
        email: email,
        paymentStartDate: this.selectedFilters["paymentStartDate"],
        paymentEndDate: this.selectedFilters["paymentEndDate"],
      };

      const { success } = await BackofficeService.sendInvoicesReport(filters);
      if (success) {
        this.notify({
          category: "simple",
          type: "success",
          title: "Generating the report, you should receive the e-mail in a few minutes.",
        });
      }
    },

    download(row) {
      window.open(row.fileUrl, "_blank");
    },

    updateSelectedRows(newSelectedRows) {
      this.selectedRows = newSelectedRows;
    },

    paymentStatusFieldDisplayStyle(paymentStatus) {
      switch (paymentStatus) {
        case "pending_payment":
          return "bg-yellow-100 text-yellow-800";
        case "paid":
          return "bg-green-100 text-green-800";
        default:
          return "bg-blue-100 text-blue-800";
      }
    },

    selectableRowFilter(row) {
      return row.paymentStatus === "pending_payment";
    },
  },
};
</script>
