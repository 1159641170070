<template>
  <CollapsableContent title="Sav et réparations" :open="false">
    <template #header>
      <StoreConfigurationStatusBadge :configuration-status="afterSalesService.afterSalesServiceConfigStatus" />
    </template>

    <dl class="px-4 py-5 grid grid-cols-2 gap-x-4 gap-y-8">
      <div>
        <dt class="text-sm font-bold text-gray-500">{{ $t("store.repair_workshop") }}</dt>
        <dd class="mt-1 text-sm text-gray-900">
          <Selector
            v-if="editMode"
            id="repairWorkshop"
            name="repairWorkshop"
            :options="repairWorkshopOptions"
            v-model="formData.repairWorkshop"
          />
          <span v-else-if="afterSalesService.repairWorkshop != null">
            {{ $t(`${afterSalesService.repairWorkshop}`) }}
          </span>
        </dd>
      </div>
      <div v-if="afterSalesService.repairWorkshop || formData.repairWorkshop">
        <dt class="text-sm font-bold text-gray-500">{{ $t("store.repair_workshop_email") }}</dt>
        <dd class="mt-1 text-sm text-gray-900">
          <FormField
            v-if="editMode"
            id="repairWorkshopEmail"
            name="repairWorkshopEmail"
            v-model="formData.repairWorkshopEmail"
            :placeholder="afterSalesService.repairWorkshopEmail"
          />
          <p v-else>
            {{ afterSalesService.repairWorkshopEmail }}
          </p>
        </dd>
      </div>
    </dl>
    <div class="p-3 flex flex-row-reverse gap-x-4">
      <GenericButton v-if="isStoreSetup" @click="validate">Valider</GenericButton>
      <GenericButton v-if="isStoreSetup" @click="reject">Refuser</GenericButton>
      <GenericButton v-if="!editMode" @click="editMode = true">Modifier</GenericButton>
      <template v-if="editMode">
        <GenericButton @click="editMode = false">Annuler</GenericButton>
        <GenericButton @click="submitEdit">Mettre à jour</GenericButton>
      </template>
    </div>
  </CollapsableContent>
</template>

<script>
import { mapActions } from "vuex";
import { CollapsableContent } from "@estaly/ui";
import StoreConfigurationStatusBadge from "@/components/StoreConfigurationStatusBadge.vue";
import GenericButton from "@/components/utils/GenericButton.vue";
import { FormField } from "@estaly/ui";
import { Selector } from "@estaly/ui";
import BackofficeService from "@/api/services/backoffice.js";

export default {
  components: {
    CollapsableContent,
    StoreConfigurationStatusBadge,
    GenericButton,
    FormField,
    Selector,
  },
  props: ["store", "isStoreSetup"],

  data: () => {
    return {
      editMode: false,
      formData: {
        repairWorkshop: false,
        repairWorkshopEmail: "",
      },
      repairWorkshopOptions: [
        { value: true, label: "Oui" },
        { value: false, label: "Non" },
      ],
    };
  },

  mounted() {
    this.formData = { ...this.afterSalesService };
  },

  computed: {
    afterSalesService() {
      return this.store.configurationAfterSalesService;
    },
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    validate() {
      this.$emit("validate");
    },

    reject() {
      this.$emit("reject");
    },

    async submitEdit() {
      const response = await BackofficeService.updateStore(this.store.id, this.formData);
      if (!response?.success) return;

      this.notify({
        category: "simple",
        type: "success",
        title: "Mis à jour effectuée",
      });
      this.$emit("updated");
      this.editMode = false;
    },
  },
};
</script>
