<template>
  <div>
    <h1 class="text-xl mt-6 pb-4 pl-2 font-extrabold tracking-tight text-gray-900">Sinistres</h1>
    <div class="bg-gray shadow overflow-hidden sm:rounded-lg">
      <div class="border-t border-gray-200 bg-white">
        <Loading v-if="loading" />
        <PaginatedClaimList
          v-else
          :total-number-of-claims="totalNumberOfClaims"
          :current-page="currentPage"
          :claims="claims"
          @changePage="handleChangePage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BackofficeService from "@/api/services/backoffice";
import PaginatedClaimList from "@/components/claim/list/PaginatedClaimList.vue";
import { Loading } from "@estaly/ui";

export default {
  components: {
    PaginatedClaimList,
    Loading,
  },
  props: {
    planSaleCustomId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      claims: [],
      totalNumberOfClaims: 1,
      currentPage: 1,
    };
  },
  watch: {
    async planSaleCustomId() {
      await this.retrieveClaims();
    },
  },
  async mounted() {
    await this.retrieveClaims();
  },
  methods: {
    async retrieveClaims() {
      this.loading = true;
      const response = await BackofficeService.getClaims({
        page: this.currentPage,
        planSaleCustomId: this.planSaleCustomId,
      });
      if (response?.success) {
        this.claims = response.data.claims;
        this.totalNumberOfClaims = response.data.totalNumberOfClaims;
      }
      this.loading = false;
    },
    async handleChangePage(newPage) {
      this.currentPage = newPage;
      history.pushState({}, "", `?page=${newPage}`);
      await this.retrieveClaims();
    },
  },
};
</script>
