<template>
  <div v-if="this.generalInformations" class="bg-white shadow sm:rounded-lg">
    <div class="grid grid-cols-1 sm:grid-cols-2 px-4 py-5 sm:px-6">
      <div class="flex justify-center items-center">
        <img :src="this.generalInformations.logoUrl" :alt="this.generalInformations.name" class="max-w-full h-auto" />
      </div>
      <div>
        <div class="flex justify-between">
          <h3 class="px-4 text-lg leading-6 font-medium text-gray-900 font-bold">
            {{ this.generalInformations.name }}
          </h3>
          <GenericButton v-if="isStoreSetup" @click="validateStoreConfig">Valider la configuration</GenericButton>
        </div>
        <div>
          <dl class="px-4 py-5 grid grid-cols-2 gap-x-4 gap-y-8">
            <div>
              <dt class="text-sm font-bold text-gray-500">{{ $t("Id") }}</dt>
              <dd class="mt-1 text-sm text-gray-900">
                <FieldSpan :style-to-add="'bg-purple-600 text-white'" :value="this.generalInformations.id" />
              </dd>
            </div>
            <div>
              <dt class="text-sm font-bold text-gray-500">{{ $t("Status") }}</dt>
              <dd class="mt-1 text-sm text-gray-900">
                <span :class="statusClass">{{ $t(`store.status.${generalInformations.status}`) }}</span>
              </dd>
            </div>
            <div>
              <dt class="text-sm font-bold text-gray-500">
                {{ $t("Organization") }}
              </dt>
              <dd class="mt-1 text-sm text-gray-900">
                <a class="text-blue-400 underline hover:font-bold" :href="organizationUrl">
                  {{ this.organization.businessName }}
                </a>
              </dd>
            </div>
            <div>
              <dt class="text-sm font-bold text-gray-500">Source</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ this.generalInformations.source }}
              </dd>
            </div>
            <div v-if="this.generalInformations.shop">
              <dt class="text-sm font-bold text-gray-500">{{ $t("Link") }}</dt>
              <dd class="mt-1 text-sm text-gray-900">
                <a
                  class="text-blue-400 underline hover:font-bold"
                  target="_blank"
                  :href="this.generalInformations.shop"
                >
                  Lien
                </a>
              </dd>
            </div>
            <div>
              <dt class="text-sm font-bold text-gray-500">
                {{ $t("Post Purchase") }}
              </dt>
              <dd class="mt-1 text-sm text-gray-900">
                <FieldSpan
                  :style-to-add="this.generalInformations.postPurchaseEnabled ? 'bg-green-100' : 'bg-red-100'"
                  :value="this.generalInformations.postPurchaseEnabled"
                />
              </dd>
            </div>
            <div>
              <div class="flex">
                <dt class="text-sm font-bold text-gray-500">
                  {{ $t("Monthly sales target") }}
                </dt>
                <PencilIcon class="ml-4 h-6 cursor-pointer" @click="isEditingMonthlyTarget = true" />
              </div>
              <div v-if="isEditingMonthlyTarget" class="flex">
                <input
                  type="number"
                  v-model="monthlySalesTargetInternalValue"
                  class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
                />
                <div class="flex items-center">
                  <SaveIcon class="ml-4 h-5 cursor-pointer text-primary-dark" @click="saveMonthlySalesTarget" />
                </div>
              </div>
              <div v-else class="mt-1 text-sm text-gray-900">
                {{ monthlySalesTargetInternalValue }}
              </div>
            </div>
            <div>
              <dt class="text-sm font-bold text-gray-500">
                {{ $t("Sales field manager") }}
              </dt>
              <SalesFieldManagerSelector
                :salesFieldManagers="salesFieldManagers"
                :selectedSalesFieldManager="generalInformations.salesFieldManager"
                @update:selectedSalesFieldManager="onSalesFieldManagerChange"
              />
            </div>
            <div>
              <dt class="text-sm font-bold text-gray-500">Lien public de souscription</dt>
              <dd
                class="mt-1 text-sm text-gray-900 cursor-pointer hover:underline hover:text-primary-dark"
                @click="copyToClipboard"
              >
                Copier le lien
              </dd>
            </div>
            <div>
              <dt class="text-sm font-bold text-gray-500">Région</dt>
              <Selector id="region" name="region" v-model="region" :options="generalInformations.regions" />
            </div>
            <div>
              <dt class="text-sm font-bold text-gray-500">Catégorie</dt>
              <Selector id="category" name="category" v-model="category" :options="generalInformations.categories" />
            </div>
            <div v-if="isStoreActive && isStoreOffline">
              <dt class="text-sm font-bold text-gray-500">Date de formation</dt>
              <button
                class="bg-white hover:bg-gray-100 py-2 px-4 border border-gray-400 rounded"
                @click="editOnboardingDateModalOpen = true"
              >
                <template v-if="generalInformations.onboardingDate || onboardingDate">
                  {{ formatOnboardingDate(generalInformations.onboardingDate || onboardingDate) }}
                </template>
                <template v-else>Configurer</template>
              </button>
              <Modal :open="editOnboardingDateModalOpen" @close="editOnboardingDateModalOpen = false">
                <FormField
                  id="onboarding_date"
                  name="onboarding_date"
                  label="Configurer la date de formation"
                  type="date"
                  v-model="onboardingDate"
                />
                <div class="flex justify-end mt-4">
                  <GenericButton @click="confirmOnboardingDate">Confirmer</GenericButton>
                </div>
              </Modal>
            </div>
            <div v-if="isStoreOffline">
              <div class="flex">
                <dt class="text-sm font-bold text-gray-500">Split vendeur gain challenge</dt>
                <PencilIcon class="ml-4 h-6 cursor-pointer" @click="isEditingChallengeSellerCommissionRatio = true" />
              </div>
              <div v-if="isEditingChallengeSellerCommissionRatio" class="flex">
                <input
                  type="number"
                  v-model="challengeSellerCommissionRatioInternalValue"
                  class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
                />
                <div class="flex items-center">
                  <SaveIcon
                    class="ml-4 h-5 cursor-pointer text-primary-dark"
                    @click="saveChallengeSellerCommissionRatio"
                  />
                </div>
              </div>
              <div v-else class="mt-1 text-sm text-gray-900">
                {{ challengeSellerCommissionRatioInternalValue }}
              </div>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PencilIcon, SaveIcon } from "@heroicons/vue/outline";
import FieldSpan from "@/components/utils/FieldSpan.vue";
import SalesFieldManagerSelector from "@/components/analytics/retail/utils/SalesFieldManagerSelector.vue";
import GenericButton from "@/components/utils/GenericButton.vue";
import BackofficeService from "@/api/services/backoffice";
import { FormField } from "@estaly/ui";
import { Selector, Modal } from "@estaly/ui";
import { mapActions } from "vuex";
import { format } from "date-fns";

export default {
  components: {
    PencilIcon,
    SaveIcon,
    FieldSpan,
    SalesFieldManagerSelector,
    Selector,
    GenericButton,
    Modal,
    FormField,
  },

  props: ["store"],
  emits: ["validateStoreConfig", "update:selectedSalesFieldManager"],

  data() {
    return {
      editOnboardingDateModalOpen: false,
      onboardingDate: this.store.generalInformations.onboardingDate,
      regionInternalValue: this.store.generalInformations.region,
      categoryInternalValue: this.store.generalInformations.category,
      monthlySalesTargetInternalValue: this.store.generalInformations.monthlySalesTarget,
      challengeSellerCommissionRatioInternalValue: this.store.generalInformations.challengeSellerCommissionRatio,
      isEditingMonthlyTarget: false,
      isEditingChallengeSellerCommissionRatio: false,
    };
  },

  computed: {
    generalInformations() {
      return this.store.generalInformations;
    },

    salesFieldManagers() {
      return this.store.salesFieldManagers;
    },

    organization() {
      return this.store.organization;
    },

    organizationUrl() {
      return `/organizations/${this.organization.id}`;
    },

    statusClass() {
      return this.isStoreSetup ? "bg-secondary" : "";
    },
    isStoreSetup() {
      return this.generalInformations.status === "setup";
    },
    isStoreActive() {
      return this.generalInformations.status === "active";
    },
    isStoreOffline() {
      return this.generalInformations.source === "offline";
    },
    region: {
      get() {
        return this.regionInternalValue || this.generalInformations.region;
      },
      async set(region) {
        const success = await this.updateStore({ region });
        if (success) this.regionInternalValue = region;
      },
    },
    category: {
      get() {
        return this.categoryInternalValue || this.generalInformations.category;
      },
      async set(category) {
        const success = await this.updateStore({ category });
        if (success) this.categoryInternalValue = category;
      },
    },
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    onSalesFieldManagerChange: function (salesFieldManagerId) {
      this.$emit("update:selectedSalesFieldManager", salesFieldManagerId);
    },

    copyToClipboard() {
      navigator.clipboard.writeText(this.generalInformations.publicSubscriptionLink).then(() => {});
    },

    validateStoreConfig() {
      this.$emit("validateStoreConfig");
    },

    async confirmOnboardingDate() {
      if (!this.onboardingDate) return;

      const success = await this.updateStore({ onboarding_date: this.onboardingDate });
      if (success) this.editOnboardingDateModalOpen = false;
    },

    formatOnboardingDate(date) {
      return format(new Date(date), "dd/MM/yyyy");
    },

    async saveMonthlySalesTarget() {
      const success = await this.updateStore({
        monthly_sales_target: this.monthlySalesTargetInternalValue,
      });
      if (success) this.isEditingMonthlyTarget = !this.isEditingMonthlyTarget;
    },

    async saveChallengeSellerCommissionRatio() {
      const success = await this.updateStore({
        challenge_seller_commission_ratio: this.challengeSellerCommissionRatioInternalValue,
      });
      if (success) this.isEditingChallengeSellerCommissionRatio = !this.isEditingChallengeSellerCommissionRatio;
    },

    async updateStore(data) {
      const { success } = await BackofficeService.updateStore(this.generalInformations.id, data);
      if (success) {
        await this.notify({
          category: "simple",
          type: "success",
          title: "Mise à jour effectuée",
        });
        this.$emit("storeUpdated");
      }
      return success;
    },
  },
};
</script>
