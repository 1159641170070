<template>
  <PageHeader title="Invoices" />
  <TabsNavigation :tabs="tabs" />
</template>

<script>
import { TabsNavigation } from "@estaly/ui";
import PageHeader from "@/components/utils/PageHeader.vue";

export default {
  components: { TabsNavigation, PageHeader },

  data() {
    return {
      tabs: [
        { name: "invoices", displayName: "Commissions", path: "/billing/invoices" },
        { name: "claim_invoices", displayName: "Sinistres", path: "/billing/claim_invoices" },
      ],
    };
  },
};
</script>
