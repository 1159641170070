<template>
  <Modal :open="open" @close="close">
    <div>
      <h1 class="text-center text-md">
        Êtes-vous sûr de désactiver ce point de vente ? Cette action est irréversible.
      </h1>
      <div class="mt-4 flex justify-center">
        <GenericButton @onClick="disableStore">Supprimer</GenericButton>
      </div>
    </div>
  </Modal>
</template>

<script>
import { Modal } from "@estaly/ui";
import GenericButton from "@/components/utils/GenericButton.vue";

export default {
  components: {
    Modal,
    GenericButton,
  },

  props: ["open", "storeId"],

  methods: {
    disableStore() {
      this.$emit("disableStore", this.storeId);
    },

    close() {
      this.$emit("closeDisableStoreModal");
    },
  },
};
</script>
