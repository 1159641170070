<template>
  <CollapsableContent title="Informations générales" :open="false">
    <template #header>
      <StoreConfigurationStatusBadge :configuration-status="generalInformations.generalInformationsConfigStatus" />
    </template>
    <dl class="px-4 py-5 grid grid-cols-2 gap-x-4 gap-y-8">
      <div>
        <dt class="text-sm font-bold text-gray-500">{{ $t("store.business_name") }}</dt>
        <dd class="mt-1 text-sm text-gray-900">
          <FormField
            v-if="editMode"
            id="businessName"
            name="businessName"
            v-model="formData.businessName"
            :placeholder="generalInformations.businessName"
          />
          <p v-else>
            {{ generalInformations.businessName }}
          </p>
        </dd>
      </div>
      <div>
        <dt class="text-sm font-bold text-gray-500">Sales channel</dt>
        <dd class="mt-1 text-sm text-gray-900">
          <FormField
            v-if="editMode"
            id="salesChannel"
            name="salesChannel"
            v-model="formData.salesChannel"
            :placeholder="generalInformations.salesChannel"
          />
          <p v-else>
            {{ generalInformations.salesChannel }}
          </p>
        </dd>
      </div>
      <div>
        <dt class="text-sm font-bold text-gray-500">Adresse postale</dt>
        <dd class="mt-1 text-sm text-gray-900">
          <LocationField
            v-if="editMode"
            id="shippingAddress"
            placeholder="Adresse postale"
            v-model="formData.shippingAddress"
            required
          />
          <p v-else>
            {{ formatLocation(generalInformations.shippingAddress) }}
          </p>
        </dd>
      </div>
      <div>
        <dt class="text-sm font-bold text-gray-500">Adresse de facturation</dt>
        <dd class="mt-1 text-sm text-gray-900">
          <FormField
            v-if="editMode"
            id="billingAddress"
            name="billingAddress"
            v-model="formData.billingAddress"
            :placeholder="generalInformations.billingAddress"
          />
          <p v-else>
            {{ generalInformations.billingAddress }}
          </p>
        </dd>
      </div>
      <div>
        <dt class="text-sm font-bold text-gray-500">{{ $t("store.revenue_range") }}</dt>
        <dd class="mt-1 text-sm text-gray-900">
          <p>{{ generalInformations.revenueRange }}</p>
        </dd>
      </div>
    </dl>
    <div class="p-3 flex flex-row-reverse gap-x-4">
      <GenericButton v-if="isStoreSetup" @click="validate">Valider</GenericButton>
      <GenericButton v-if="isStoreSetup" @click="reject">Refuser</GenericButton>
      <GenericButton v-if="!editMode" @click="editMode = true">Modifier</GenericButton>
      <template v-if="editMode">
        <GenericButton @click="editMode = false">Annuler</GenericButton>
        <GenericButton @click="submitEdit">Mettre à jour</GenericButton>
      </template>
    </div>
  </CollapsableContent>
</template>

<script>
import { mapActions } from "vuex";
import { CollapsableContent } from "@estaly/ui";
import StoreConfigurationStatusBadge from "@/components/StoreConfigurationStatusBadge.vue";
import GenericButton from "@/components/utils/GenericButton.vue";
import { FormField } from "@estaly/ui";
import BackofficeService from "@/api/services/backoffice.js";
import { formatLocation } from "@estaly/ui/src/utils/location_formatter";
import { LocationField } from "@estaly/ui";

export default {
  components: {
    FormField,
    LocationField,
    CollapsableContent,
    StoreConfigurationStatusBadge,
    GenericButton,
  },
  props: ["store", "isStoreSetup"],

  data: () => {
    return {
      editMode: false,
      formData: {
        businessName: "",
        salesChannel: "",
        billingAddress: "",
        shippingAddress: "",
      },
    };
  },

  mounted() {
    this.formData = { ...this.generalInformations };
  },

  computed: {
    generalInformations() {
      return this.store.configurationGeneralInformations;
    },
  },

  methods: {
    formatLocation,
    ...mapActions("notifications", ["notify"]),

    validate() {
      this.$emit("validate");
    },

    reject() {
      this.$emit("reject");
    },

    async submitEdit() {
      const response = await BackofficeService.updateStore(this.store.id, this.formData);
      if (!response?.success) return;

      this.notify({
        category: "simple",
        type: "success",
        title: "Mis à jour effectuée",
      });
      this.$emit("updated");
      this.editMode = false;
    },
  },
};
</script>
