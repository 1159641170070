<template>
  <Modal :open="open" @close="emitClose">
    <div class="flex justify-between items-center">
      <p>{{ $t("Generate and copy the renewal link") }}</p>
      <GenericButton @click="emitGetRenewPlanSaleLink">Générer</GenericButton>
    </div>
    <div class="flex justify-between items-center mt-10">
      <p>{{ $t("Send the renewal link directly to the client") }}</p>
      <GenericButton @click="emitRenewPlanSale">Envoyer</GenericButton>
    </div>
  </Modal>
</template>

<script>
import { Modal } from "@estaly/ui";
import GenericButton from "@/components/utils/GenericButton.vue";

export default {
  components: {
    GenericButton,
    Modal,
  },

  props: ["open", "planSale"],

  methods: {
    emitClose: function () {
      this.$emit("close");
    },

    emitGetRenewPlanSaleLink: function () {
      this.$emit("getRenewPlanSaleLink");
    },

    emitRenewPlanSale: function () {
      this.$emit("renewPlanSale");
    },
  },
};
</script>
