import { isPresent } from "./validation";

export function formatLocation(location) {
  if (!location) return "";

  let addr = location.address1;
  if (isPresent(location.address2)) addr += `, ${location.address2}`;
  if (isPresent(location.city)) addr += `, ${location.city}`;
  if (isPresent(location.zipCode)) addr += `, ${location.zipCode}`;
  if (isPresent(location.country)) addr += `, ${location.country}`;
  return addr;
}
