<template>
  <div v-if="planSale !== undefined && planSale !== null">
    <div>
      <div>
        <div class="flex sm:flex-row flex-col justify-between">
          <h1 class="text-3xl pb-4 font-extrabold tracking-tight text-gray-900">Contrat</h1>
          <div class="flex gap-4">
            <div v-if="renewalInformations?.renewalAvailable && renewalInformations?.expiredOrExpiringSoon">
              <GenericButton @click="openRenewalModal = true">Renouveler</GenericButton>
              <RenewalModal
                :open="openRenewalModal"
                :plan-sale="planSale"
                @close="openRenewalModal = false"
                @renewPlanSale="renewPlanSale"
                @getRenewPlanSaleLink="getRenewPlanSaleLink"
              />
            </div>
            <div v-if="planSale.status !== 'cancelled'">
              <GenericButton @click="openModal = true">Résilier</GenericButton>
              <CancellationModal :open="openModal" @close="openModal = false" @cancelPlanSale="cancelPlanSale" />
            </div>
            <div v-if="planSale.status === 'active'">
              <GenericButton @click="openUpdateModal = true">Modifier</GenericButton>
              <UpdateModal
                :open="openUpdateModal"
                :planSale="planSale"
                @closeUpdateModal="openUpdateModal = false"
                @updatePlanSale="updatePlanSale"
              />
            </div>
            <div v-if="planSale.status !== 'cancelled'">
              <GenericButton @click="sendConfirmationEmail">Envoyer le mail de confirmation</GenericButton>
            </div>
          </div>
        </div>
        <Cancellation :cancellation="cancellation" v-if="cancellation" />
        <p class="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
          Information sur la protection
          <span class="font-bold">{{ planSale.id }}</span>
        </p>
      </div>

      <div class="mt-4">
        <div class="mt-2 border-t border-gray-100">
          <dl class="bg-white px-4 sm:px-6 py-5 grid grid-cols-2 gap-x-4 gap-y-6">
            <h2 class="col-span-2 text-xl font-bold">Client</h2>
            <div>
              <dt class="text-sm font-bold text-gray-500">Nom Prénom</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ planSale.customer.firstName }}
                {{ planSale.customer.lastName }}
              </dd>
            </div>
            <div>
              <dt class="text-sm font-bold text-gray-500">Email</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ planSale.customer.email }}
              </dd>
            </div>
            <div>
              <dt class="text-sm font-bold text-gray-500">Adresse</dt>
              <dd class="mt-1 text-sm text-gray-900">
                <p v-if="planSale.customer.fullAddress">
                  {{ planSale.customer.fullAddress }}
                </p>
                <p class="font-bold" v-else>—</p>
              </dd>
            </div>
            <div>
              <dt class="text-sm font-bold text-gray-500">Numéro de téléphone</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ planSale.customer.phone }}
              </dd>
            </div>
            <div>
              <dt class="text-sm font-bold text-gray-500">Date de naissance</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ planSale.customer.birthDate }}
              </dd>
            </div>
            <div>
              <dt class="text-sm font-bold text-gray-500">Lieu de naissance</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ customerBirthPlace }}
              </dd>
            </div>
            <div>
              <dt class="text-sm font-bold text-gray-500">Création du compte</dt>
              <LeadInfoBox
                class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 cursor-pointer hover:text-primary-dark"
                :textToCopy="planSale.customer.signUpUrl"
              >
                Lien
              </LeadInfoBox>
            </div>
            <div v-if="planSale.orderReferenceId">
              <dt class="text-sm font-bold text-gray-500">Numéro de commande marchand</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ planSale.orderReferenceId }}
              </dd>
            </div>
          </dl>
        </div>
      </div>

      <div class="mt-6">
        <div class="mt-2 border-t border-gray-100">
          <dl class="bg-white px-4 sm:px-6 py-5 grid grid-cols-2 gap-x-4 gap-y-6">
            <h2 class="col-span-2 text-xl font-bold">Assurance</h2>
            <div>
              <dt class="text-sm font-bold text-gray-500">Formule</dt>
              <dd class="mt-1 text-sm text-gray-900">{{ planSale.title }}</dd>
            </div>
            <div>
              <dt class="text-sm font-bold text-gray-500">Offre d'assurance</dt>
              <dd class="mt-1 text-sm text-gray-900">{{ planSale.category }}</dd>
            </div>
            <div>
              <dt class="text-sm font-bold text-gray-500">Prix de l'assurance</dt>
              <dd class="mt-1 text-sm text-gray-900">{{ formatPrice(planSale.price) }}</dd>
            </div>
            <div>
              <dt class="text-sm font-bold text-gray-500">Paiement</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ planSale.monthly ? "Mensuel" : "Annuel" }}
              </dd>
            </div>
            <div>
              <dt class="text-sm font-bold text-gray-500">Date d'achat</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ planSale.purchaseDate }}
              </dd>
            </div>
            <div>
              <dt class="text-sm font-bold text-gray-500">Date de début de contrat</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ planSale.date }}
              </dd>
            </div>
            <div>
              <dt class="text-sm font-bold text-gray-500">Bulletin D'adhésion</dt>
              <LeadInfoBox
                class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 cursor-pointer underline-offset-4 hover:text-primary-dark"
                :textToCopy="planSale.membershipAgreement"
              >
                Lien
              </LeadInfoBox>
            </div>
            <div>
              <dt class="text-sm font-bold text-gray-500">Encaissé par</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ planSale.chargedBy }}
              </dd>
            </div>
            <div>
              <dt class="text-sm font-bold text-gray-500">Boutique</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ planSale.store?.salesChannel }}
              </dd>
            </div>
            <div v-if="planSale.monthly">
              <dt class="text-sm font-bold text-gray-500">Abonnement Stripe</dt>
              <dd class="mt-1 text-sm text-gray-900">
                <a
                  :href="`https://dashboard.stripe.com/subscriptions/${planSale.subscriptionReferenceId}`"
                  target="_blank"
                  class="cursor-pointer underline underline-offset-1 text-primary hover:text-primary-dark"
                >
                  {{ planSale.subscriptionReferenceId }}
                </a>
              </dd>
            </div>
            <div>
              <dt class="text-sm font-bold text-gray-500">Vendeur</dt>
              <dd class="mt-1 text-sm text-gray-900">
                <p v-if="sellerName">{{ sellerName }}</p>
                <p class="font-bold" v-else>—</p>
              </dd>
            </div>
          </dl>
        </div>
      </div>

      <div class="mt-6">
        <InsuredProductsInformations :products="planSale.products" />
      </div>
    </div>
    <PlanSaleBillingDetails :planSaleId="planSale.id" :planSaleBillings="planSale.planSaleBillings" />
    <ClaimsIndex :planSaleCustomId="planSale.id" />
  </div>
</template>

<script>
import BackofficeService from "@/api/services/backoffice";
import PlanSaleBillingDetails from "@/views/plan_sales/details/PlanSaleBillingDetails.vue";
import LeadInfoBox from "@/views/leads/LeadInfoBox.vue";
import Cancellation from "@/views/plan_sales/details/Cancellation.vue";
import CancellationModal from "@/components/plan_sale/CancellationModal.vue";
import UpdateModal from "@/components/plan_sale/UpdateModal.vue";
import InsuredProductsInformations from "@/views/plan_sales/details/InsuredProductsInformations.vue";
import GenericButton from "@/components/utils/GenericButton.vue";
import { mapActions } from "vuex";
import RenewalModal from "@/components/plan_sale/RenewalModal.vue";
import ClaimsIndex from "@/components/claim/details/information/ClaimsIndex.vue";
import { formatPrice } from "@estaly/ui/src/utils/price_formatter";
import { toCamelCase } from "@estaly/ui/src/utils/data_formatter";

export default {
  components: {
    RenewalModal,
    PlanSaleBillingDetails,
    LeadInfoBox,
    Cancellation,
    GenericButton,
    CancellationModal,
    UpdateModal,
    InsuredProductsInformations,
    ClaimsIndex,
  },

  data: () => {
    return {
      planSale: null,
      openModal: false,
      openUpdateModal: false,
      openRenewalModal: false,
    };
  },

  beforeMount() {
    this.getPlanSale();
  },

  computed: {
    customerBirthPlace() {
      return [this.planSale.customer.birthCity, this.planSale.customer.birthCountry].filter(Boolean).join(", ");
    },

    sellerName() {
      return [this.planSale.seller?.firstName, this.planSale.seller?.lastName].filter(Boolean).join(" ");
    },

    renewalInformations() {
      return this.planSale?.renewalInformations;
    },
  },

  methods: {
    ...mapActions("notifications", ["notify"]),
    ...mapActions("backoffice", ["withLoader"]),
    formatPrice,

    async getPlanSale() {
      const id = this.$route.params.id;
      const includes = ["renewal_informations", "seller", "cancellation", "plan_sale_billings", "store"];
      const response = await BackofficeService.getPlanSale(id, includes);
      if (!response?.success) return;

      const formattedResponse = toCamelCase(response.data);
      this.planSale = formattedResponse;
      this.cancellation = this.planSale.cancellation;
    },

    copyToClipboard(textToCopy) {
      navigator.clipboard.writeText(textToCopy).then(() => {});
    },

    async cancelPlanSale(cancellationReason, feedbackMessage) {
      await this.withLoader(async () => {
        const { success } = await BackofficeService.cancelPlanSale(
          this.planSale.id,
          cancellationReason,
          feedbackMessage,
        );
        if (success) {
          this.openModal = false;
          this.notify({
            category: "simple",
            type: "success",
            title: "This plan sale has been cancelled.",
          });
          this.getPlanSale();
        }
      });
    },

    async updatePlanSale(updatePlanSaleInformations) {
      await this.withLoader(async () => {
        const { success } = await BackofficeService.updatePlanSale(this.planSale.id, updatePlanSaleInformations);
        if (success) {
          this.openUpdateModal = false;
          this.notify({
            category: "simple",
            type: "success",
            title: "This plan sale has been updated.",
          });
          this.getPlanSale();
        }
      });
    },

    async getRenewPlanSaleLink() {
      const response = await BackofficeService.getRenewPlanSaleLink(this.planSale.id);
      this.copyToClipboard(response.data.plan_sale_renewal_link);
      await this.notify({
        category: "simple",
        type: "success",
        title: "Link copied to clipboard!",
      });
      this.openRenewalModal = false;
    },

    async renewPlanSale() {
      const { success } = await BackofficeService.renewPlanSale(this.planSale.id);
      if (!success) return;

      await this.notify({
        category: "simple",
        type: "success",
        title: "Email successfully sent!",
      });
      this.openRenewalModal = false;
    },

    async sendConfirmationEmail() {
      if (!confirm("Envoyer le mail de confirmation de souscription ?")) return;

      const { success } = await BackofficeService.sendConfirmationEmail(this.planSale.id);
      if (!success) return;

      await this.notify({
        category: "simple",
        type: "success",
        title: "Email envoyé",
      });
    },
  },
};
</script>
