<template>
  <CollapsableContent title="Coordonnées du magasin" :open="false">
    <template #header>
      <StoreConfigurationStatusBadge :configuration-status="contactInformations.contactInformationsConfigStatus" />
    </template>
    <dl class="px-4 py-5 grid grid-cols-2 gap-x-4 gap-y-8">
      <div>
        <dt class="text-sm font-bold text-gray-500">{{ $t("store.email") }}</dt>
        <dd class="mt-1 text-sm text-gray-900">
          <p v-if="!editMode">
            {{ sellersProfile?.email }}
          </p>
          <template v-else>
            <Loading v-if="loadingUsers" />
            <Alert v-else-if="!sellersProfileIdOptions.length" type="error">
              Aucun utilisateur avec le role vendeur n'a été trouvé
            </Alert>
            <Selector
              v-else
              id="sellersProfileId"
              name="sellersProfileId"
              label="Sélectionnez un utilisateur avec le role vendeur"
              :options="sellersProfileIdOptions"
              v-model="formData.sellersProfileId"
            />
          </template>
        </dd>
      </div>
      <!-- Referent -->
      <template v-if="editMode">
        <div>
          <dt class="text-sm font-bold text-gray-500">Référent</dt>
          <dd class="mt-1 text-sm text-gray-900">
            <Loading v-if="loadingUsers" />
            <Alert v-else-if="!referentEmailOptions.length" type="error">Aucun utilisateur</Alert>
            <Selector
              v-else
              id="referentId"
              name="referentId"
              label="Sélectionnez un utilisateur en tant que référent"
              :options="referentEmailOptions"
              v-model="formData.referentEmail"
            />
          </dd>
        </div>
      </template>
      <template v-else>
        <div>
          <dt class="text-sm font-bold text-gray-500">{{ $t("store.referent_last_name") }}</dt>
          <dd class="mt-1 text-sm text-gray-900">
            <p>
              {{ referent?.lastName }}
            </p>
          </dd>
        </div>
        <div>
          <dt class="text-sm font-bold text-gray-500">{{ $t("store.referent_first_name") }}</dt>
          <dd class="mt-1 text-sm text-gray-900">
            <p>
              {{ referent?.firstName }}
            </p>
          </dd>
        </div>
        <div>
          <dt class="text-sm font-bold text-gray-500">{{ $t("store.referent_email") }}</dt>
          <dd class="mt-1 text-sm text-gray-900">
            <p>
              {{ referent?.email }}
            </p>
          </dd>
        </div>
        <div>
          <dt class="text-sm font-bold text-gray-500">{{ $t("store.referent_phone") }}</dt>
          <dd class="mt-1 text-sm text-gray-900">
            <p>
              {{ referent?.phone }}
            </p>
          </dd>
        </div>
      </template>

      <div>
        <dt class="text-sm font-bold text-gray-500">{{ $t("store.referent_role") }}</dt>
        <dd class="mt-1 text-sm text-gray-900">
          <Selector
            v-if="editMode"
            id="referentRole"
            name="referentRole"
            :options="roles"
            v-model="formData.referentRole"
          />
          <p v-else>
            {{ referentRole }}
          </p>
        </dd>
      </div>
    </dl>
    <div class="p-3 flex flex-row-reverse gap-x-4">
      <GenericButton v-if="isStoreSetup" @click="validate">Valider</GenericButton>
      <GenericButton v-if="isStoreSetup" @click="reject">Refuser</GenericButton>
      <GenericButton v-if="!editMode" @click="editMode = true">Modifier</GenericButton>
      <template v-if="editMode">
        <GenericButton @click="editMode = false">Annuler</GenericButton>
        <GenericButton @click="submitEdit">Mettre à jour</GenericButton>
      </template>
    </div>
  </CollapsableContent>
</template>

<script>
import { mapActions } from "vuex";
import { CollapsableContent } from "@estaly/ui";
import StoreConfigurationStatusBadge from "@/components/StoreConfigurationStatusBadge.vue";
import GenericButton from "@/components/utils/GenericButton.vue";
import { Alert, Loading, Selector } from "@estaly/ui";
import BackofficeService from "@/api/services/backoffice.js";

export default {
  components: {
    Alert,
    Loading,
    CollapsableContent,
    StoreConfigurationStatusBadge,
    GenericButton,
    Selector,
  },
  props: ["store", "isStoreSetup"],

  data: () => {
    return {
      editMode: false,
      formData: {
        sellersProfileId: null,
        referentEmail: null,
        referentRole: "",
      },
      roles: [],
      loadingUsers: false,
      users: [],
    };
  },

  mounted() {
    this.formData = {
      sellersProfileId: this.contactInformations.sellersProfile?.id,
      referentEmail: this.contactInformations.referent?.email,
      referentRole: this.contactInformations.referentRole,
    };
    this.roles = [
      {
        value: "store_manager",
        label: this.$t("store.referent_role_values.store_manager"),
      },
      {
        value: "director",
        label: this.$t("store.referent_role_values.director"),
      },
      {
        value: "franchise_owner",
        label: this.$t("store.referent_role_values.franchise_owner"),
      },
    ];
  },

  computed: {
    contactInformations() {
      return this.store.configurationContactInformations;
    },

    sellersProfile() {
      return this.contactInformations.sellersProfile;
    },

    referent() {
      return this.contactInformations.referent;
    },

    referentRole() {
      if (!this.contactInformations.referentRole) return "";

      return this.$t(`store.referent_role_values.${this.contactInformations.referentRole}`);
    },

    sellers() {
      return this.users.filter((user) => user.role === "seller");
    },

    sellersProfileIdOptions() {
      return this.sellers.map((user) => ({
        value: user.id,
        label: user.email,
      }));
    },

    referentEmailOptions() {
      return this.users.map((user) => ({
        value: user.email,
        label: `${user.firstName} ${user.lastName} (${user.email})`,
      }));
    },
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    validate() {
      this.$emit("validate");
    },

    reject() {
      this.$emit("reject");
    },

    async submitEdit() {
      const response = await BackofficeService.updateStore(this.store.id, this.formData);
      if (!response?.success) return;

      this.notify({
        category: "simple",
        type: "success",
        title: "Mis à jour effectuée",
      });
      this.$emit("updated");
      this.editMode = false;
    },

    async refreshStoreUsers() {
      this.loadingUsers = true;
      const response = await BackofficeService.getUsers({ storeId: this.store.id });
      if (response?.success) {
        this.users = response.data.users.organizationManagers.concat(response.data.users.storeManagers);
      }
      this.loadingUsers = false;
    },
  },

  watch: {
    async editMode(value) {
      if (value) await this.refreshStoreUsers();
    },
  },
};
</script>
