import BackofficeService from "@/api/services/backoffice";

const initialState = {
  availableFilters: {
    billing: [],
    chargedBy: [],
    status: [],
    organizations: [],
    stores: [],
  },
  selectedFilters: {
    billing: [],
    chargedBy: [],
    status: [],
    organizations: [],
    stores: [],
  },
  planSales: [],
  totalCount: null,
  totalPages: null,
  keywords: "",
  startDate: null,
  endDate: null,
};

export default {
  namespaced: true,

  state: initialState,

  getters: {},

  mutations: {
    setPlanSales(state, { planSales, totalCount, totalPages }) {
      state.planSales = planSales;
      state.totalCount = totalCount;
      state.totalPages = totalPages;
    },

    setFilter(state, { filterName, filterOptions }) {
      state.selectedFilters[filterName] = filterOptions;
    },

    setKeywords(state, keywords) {
      state.keywords = keywords;
    },

    setAvailableFilters(state, { billing, chargedBy, status, organizations, stores }) {
      state.availableFilters.billing = billing;
      state.availableFilters.chargedBy = chargedBy;
      state.availableFilters.status = status;
      state.availableFilters.organizations = organizations;
      state.availableFilters.stores = stores;
    },

    setDates(state, { startDate, endDate }) {
      state.startDate = startDate;
      state.endDate = endDate;
    },
  },

  actions: {
    async getPlanSales({ state, commit }, { page }) {
      const response = await BackofficeService.getPlanSales({
        page: page,
        billing: state.selectedFilters.billing,
        charged_by: state.selectedFilters.chargedBy,
        status: state.selectedFilters.status,
        organizations: state.selectedFilters.organizations,
        stores: state.selectedFilters.stores,
        keywords: state.keywords,
        start_date: state.startDate,
        end_date: state.endDate,
      });
      if (!response?.success) return response;

      commit("setPlanSales", {
        planSales: response.data.planSales,
        totalCount: response.data.page.totalCount,
        totalPages: response.data.page.totalPages,
      });

      commit("setAvailableFilters", {
        billing: response.data.filters.billing,
        chargedBy: response.data.filters.chargedBy,
        status: response.data.filters.status,
        organizations: response.data.filters.organizations,
        stores: response.data.filters.stores,
      });
      return response;
    },

    updateSearch({ commit, dispatch }, { searchString, page }) {
      commit("setKeywords", searchString);
      return dispatch("getPlanSales", {
        page: page,
      });
    },

    updateFilter({ commit, dispatch }, { filterName, filterOptions, page }) {
      commit("setFilter", { filterName, filterOptions });
      return dispatch("getPlanSales", {
        page: page,
      });
    },

    updateDateFilter({ commit, dispatch }, { startDate, endDate, page }) {
      commit("setDates", { startDate, endDate });
      return dispatch("getPlanSales", {
        page: page,
      });
    },
  },
};
