<template>
  <div class="overflow-x-auto">
    <label class="block mb-2 text-sm font-bold text-gray-900 dark:text-white">
      Veuillez détailler les coûts de réparation
      <span class="text-red-500">*</span>
    </label>
    <table class="min-w-full bg-white border border-gray-200">
      <thead>
        <tr class="w-full border-b">
          <th class="px-4 py-2 text-left text-xs font-medium text-gray-700">Type de coût</th>
          <th class="px-4 py-2 text-left text-xs font-medium text-gray-700">Description</th>
          <th class="px-4 py-2 text-left text-xs font-medium text-gray-700">Montant unitaire (HT)</th>
          <th class="px-4 py-2 text-left text-xs font-medium text-gray-700">Montant unitaire (TTC)</th>
          <th class="px-4 py-2 text-left text-xs font-medium text-gray-700">Quantité</th>
          <th class="px-4 py-2 text-left text-xs font-medium text-gray-700">Montant total TTC</th>
          <th class="px-4 py-2 text-left text-xs font-medium text-gray-700"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index" class="border-b hover:bg-gray-100">
          <td class="px-4 py-2 text-xs flex items-center">
            {{ $t(`claim.cost_type.${item.type}`) }}
          </td>
          <td class="px-4 py-2 text-xs">{{ item.description }}</td>
          <td class="px-4 py-2 text-xs">{{ item.unitPriceBeforeTax }} {{ item.currencyCode }}</td>
          <td class="px-4 py-2 text-xs">{{ item.unitPrice }} {{ item.currencyCode }}</td>
          <td class="px-4 py-2 text-xs">{{ item.quantity }}</td>
          <td class="px-4 py-2 text-xs">{{ item.total }} {{ item.currencyCode }}</td>
          <td class="px-4 py-2 text-xs">
            <button class="text-red-500 mr-2" @click="removeItem(index)">❌</button>
          </td>
        </tr>
        <tr v-if="displayNewLine" key="new-item">
          <td class="px-4 py-2 text-xs relative mt-2">
            <Field
              as="select"
              id="type"
              name="type"
              class="mt-2 px-2 py-1 rounded-md border border-gray-300 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-primary text-xs"
              v-model="newItem.type"
            >
              <option disabled value="">Choisir une option</option>
              <option value="labor">Main d'oeuvre</option>
              <option value="part">Pièce</option>
              <option value="shipping_cost">Transport</option>
            </Field>
          </td>
          <td class="px-4 py-2 text-xs">
            <FormField
              id="description"
              v-model="newItem.description"
              name="description"
              placeholder="Description"
              field-class="px-2 py-1 border border-gray-300 rounded-md text-xs"
            />
          </td>
          <td class="px-4 py-2 text-xs">
            <FormField
              id="unitPriceBeforeTax"
              name="unitPriceBeforeTax"
              v-model="unitPriceBeforeTaxComputedProperty"
              placeholder="Montant unitaire (HT)"
              field-class="px-2 py-1 border border-gray-300 rounded-md text-xs"
            />
          </td>
          <td class="px-4 py-2 text-xs">
            <FormField
              id="unitPrice"
              required
              name="unitPrice"
              v-model="unitPriceComputedProperty"
              placeholder="Montant unitaire (TTC)"
              field-class=" px-2 py-1 border border-gray-300 rounded-md text-xs"
            />
          </td>
          <td>
            <FormField
              id="quantity"
              v-model="newItem.quantity"
              type="number"
              placeholder="Quantité"
              field-class="px-2 py-1 border border-gray-300 rounded-md text-xs w-12"
              name="quantity"
            />
          </td>
          <td class="px-4 py-2 text-xs">{{ newItem.total }}</td>
          <td class="mt-2 px-4 py-2 text-xs flex justify-between">
            <button
              @click="removeNewItemDisplay"
              :class="[
                'relative inline-flex rounded-full bg-red-400 p-1 text-white shadow-sm cursor-pointer hover:bg-red-600',
              ]"
            >
              <XIcon class="h-2 w-2" aria-hidden="true" />
            </button>
            <button
              @click="this.addNewItem"
              :class="[
                'relative inline-flex rounded-full bg-green-400 p-1 text-white shadow-sm cursor-pointer hover:bg-green-600',
              ]"
            >
              <CheckIcon class="h-2 w-2" aria-hidden="true" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      v-if="!displayNewLine"
      @click="enableNewItemDisplay"
      class="flex bg-gray-100 text-sm justify-center items-center h-10 text-gray-700 shadow hover:bg-gray-200 cursor-pointer"
    >
      <PlusIcon class="w-5 h-5 mr-1" />
      <span>Ajouter une ligne</span>
    </div>
    <div v-if="displayNewLine && addNewItemErrors && addNewItemErrors.length > 0" class="mt-5 text-xs text-red-600">
      <p>{{ addNewItemErrors }}</p>
    </div>
  </div>
</template>

<script>
import { isPresent } from "@/utils/validation";
import { CheckIcon, PlusIcon, XIcon } from "@heroicons/vue/solid";
import { FormField } from "@estaly/ui";
import * as yup from "yup";
import { Field } from "vee-validate";

const schema = yup.object().shape({
  description: yup
    .string()
    .required("La description est requise.")
    .max(255, "La description ne peut pas dépasser 255 caractères."),
  unitPriceBeforeTax: yup
    .number()
    .required("Le prix unitaire (HT) est requis.")
    .typeError("Le prix unitaire (HT) doit être un nombre.")
    .min(0, "Le prix unitaire (HT) doit être positif."),
  unitPrice: yup
    .number()
    .required("Le prix unitaire (TTC) est requis.")
    .typeError("Le prix unitaire (TTC) doit être un nombre.")
    .min(0, "Le prix unitaire (TTC) doit être positif."),
  quantity: yup
    .number()
    .required("La quantité est requise.")
    .typeError("La quantité doit être un nombre.")
    .min(1, "La quantité doit être au moins 1."),
  type: yup.string().required("Le type est requis").notOneOf([""], "Please select a valid option."),
});

export default {
  components: { Field, CheckIcon, FormField, XIcon, PlusIcon },
  props: {
    initialItems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      schema,
      items: [],
      displayNewLine: false,
      newItem: {},
      addNewItemErrors: null,
    };
  },
  created() {
    this.resetNewItem();
    if (isPresent(this.initialItems)) {
      this.items = this.initialItems;
    }
  },

  computed: {
    unitPriceBeforeTaxComputedProperty: {
      get() {
        return this.newItem.unitPriceBeforeTax;
      },

      set(newValue) {
        this.newItem.unitPriceBeforeTax = newValue;
        this.updateNewItemTotal();
        this.computeNewItemTotal();
      },
    },

    unitPriceComputedProperty: {
      get() {
        return this.newItem.unitPrice;
      },

      set(newValue) {
        this.newItem.unitPrice = newValue;
        this.updateNewItemTotalBeforeTax();
        this.computeNewItemTotal();
      },
    },
  },

  watch: {
    "newItem.quantity"() {
      this.computeNewItemTotal();
    },
  },

  methods: {
    async addNewItem() {
      try {
        await this.schema.validate(this.newItem);
        const total = (parseFloat(this.newItem.unitPrice.replace("€", "").trim()) * this.newItem.quantity).toFixed(2);
        this.items.push({ ...this.newItem, total: total });
        this.$emit("updatedItems", this.items);
        this.displayNewLine = false;
        this.resetNewItem();
      } catch (error) {
        this.addNewItemErrors = error.message;
        console.error(error);
      }
    },

    removeItem(index) {
      this.items.splice(index, 1);
      this.$emit("updatedItems", this.items);
      this.displayNewLine = false;
    },

    computeNewItemTotal() {
      this.newItem.total = ((this.newItem.unitPrice ?? 0) * (this.newItem.quantity ?? 0)).toFixed(2);
    },

    enableNewItemDisplay() {
      this.displayNewLine = true;
      this.addNewItemErrors = null;
    },

    removeNewItemDisplay() {
      this.displayNewLine = false;
      this.resetNewItem();
    },

    updateNewItemTotal() {
      this.newItem.unitPrice = (this.newItem.unitPriceBeforeTax * 1.2).toFixed(2);
    },

    updateNewItemTotalBeforeTax() {
      this.newItem.unitPriceBeforeTax = (this.newItem.unitPrice / 1.2).toFixed(2);
    },

    resetNewItem() {
      this.newItem = {
        type: "",
        description: "",
        unitPriceBeforeTax: "",
        unitPrice: "",
        quantity: 1,
        total: "",
      };
    },
  },
};
</script>
