<template>
  <MonthlyDateFilterSelector
    class="my-4"
    placeholder="Sélectionner une période"
    @newDateSelected="updateBulkTransferData"
  />
  <GenericButton @click="exportBulkTransferData">Exporter</GenericButton>
</template>

<script>
import GenericButton from "@/components/utils/GenericButton.vue";
import MonthlyDateFilterSelector from "@/components/menu/MonthlyDateFilterSelector.vue";
import BackofficeService from "@/api/services/backoffice";
import { mapActions } from "vuex";

export default {
  components: {
    GenericButton,
    MonthlyDateFilterSelector,
  },

  emits: ["success"],

  data: () => {
    return {
      bulkTransferData: {
        startDate: null,
        endDate: null,
      },
    };
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    updateBulkTransferData(startDate, endDate) {
      this.bulkTransferData["startDate"] = startDate;
      this.bulkTransferData["endDate"] = endDate;
    },

    async exportBulkTransferData() {
      const response = await BackofficeService.downloadInvoicesQontoBulkTransferCSV(this.bulkTransferData);
      if (!response?.success) return;

      if (response.status === 204) {
        this.notify({
          category: "simple",
          type: "error",
          title: "No data found for the selected period.",
        });
        return;
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "qonto_bulk_transfer.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      this.$emit("success");
      this.notify({
        category: "simple",
        type: "success",
        title: "Exporting merchants transfers file.",
      });
    },
  },
};
</script>
