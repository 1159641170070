<template>
  <div v-if="organization" class="bg-white shadow sm:rounded-lg p-4">
    <h3 class="px-4 text-lg leading-6 text-gray-900 font-bold">
      {{ organization.businessName }}
    </h3>
    <div>
      <dl class="px-4 py-5 grid grid-cols-2 sm:grid-cols-4 gap-x-4 gap-y-8">
        <div>
          <dt class="text-sm font-bold text-gray-500">{{ $t("Id") }}</dt>
          <dd class="mt-1 text-sm text-gray-900">
            <FieldSpan :style-to-add="'bg-purple-600 text-white'" :value="organization.id" />
          </dd>
        </div>
        <div>
          <dt class="text-sm font-bold text-gray-500">
            {{ $t("Name") }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            <FormField v-if="editMode" id="name" name="name" v-model="formData.name" :placeholder="organization.name" />
            <template v-else>
              {{ organization.name || "—" }}
            </template>
          </dd>
        </div>
        <div>
          <dt class="text-sm font-bold text-gray-500">
            {{ $t("Business name") }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            <FormField
              v-if="editMode"
              id="business_name"
              name="business_name"
              v-model="formData.businessName"
              :placeholder="organization.businessName"
            />
            <template v-else>
              {{ organization.businessName || "—" }}
            </template>
          </dd>
        </div>
        <div>
          <dt class="text-sm font-bold text-gray-500">
            {{ $t("Invoice address") }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            <FormField
              v-if="editMode"
              id="billing_address"
              name="billing_address"
              v-model="formData.billingAddress"
              :placeholder="organization.billingAddress"
            />
            <template v-else>
              {{ organization.billingAddress }}
            </template>
          </dd>
        </div>
        <div>
          <dt class="text-sm font-bold text-gray-500">
            {{ $t("Registration number") }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            <FormField
              v-if="editMode"
              id="registration_number"
              name="registration_number"
              v-model="formData.registrationNumber"
              :placeholder="organization.registrationNumber"
            />
            <template v-else>
              {{ organization.registrationNumber }}
            </template>
          </dd>
        </div>
        <div>
          <dt class="text-sm font-bold text-gray-500">
            {{ $t("Network type") }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            <Selector
              v-if="editMode"
              id="network_type"
              name="network_type"
              v-model="formData.networkType"
              :options="options.networkTypes"
            />
            <template v-else>
              <span v-if="organization.networkType">
                {{ $t(`organization.network_type.${organization.networkType}`) }}
              </span>
              <span v-else>—</span>
            </template>
          </dd>
        </div>
        <div>
          <dt class="text-sm font-bold text-gray-500">
            {{ $t("Revenue range") }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            <Selector
              v-if="editMode"
              id="revenue_range"
              name="revenue_range"
              v-model="formData.revenueRange"
              :options="options.revenueRanges"
            />
            <template v-else>
              <span v-if="organization.revenueRange">
                {{ $t(`organization.revenue_range.${organization.revenueRange}`) }}
              </span>
              <span v-else>—</span>
            </template>
          </dd>
        </div>
        <div>
          <dt class="text-sm font-bold text-gray-500">
            {{ $t("Total points of sale") }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            <FormField
              v-if="editMode"
              id="total_points_of_sale"
              name="total_points_of_sale"
              v-model="formData.totalPointsOfSale"
              :placeholder="organization.totalPointsOfSale"
            />
            <template v-else>
              {{ organization.totalPointsOfSale }}
            </template>
          </dd>
        </div>
        <div>
          <dt class="text-sm font-bold text-gray-500">
            {{ $t("Invoice level") }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ $t(`invoice_level.${organization.invoiceLevel}`) }}
          </dd>
        </div>
        <div>
          <dt class="text-sm font-bold text-gray-500">IBAN</dt>
          <dd class="mt-1 text-sm text-gray-900">
            <FormField v-if="ibanEditable" id="iban" name="iban" v-model="formData.iban" />
            <template v-else>
              {{ organization.iban || "—" }}
            </template>
          </dd>
        </div>
        <div>
          <dt class="text-sm font-bold text-gray-500">
            {{ $t("Source") }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ $t(organization.source) }}
          </dd>
        </div>
        <div>
          <dt class="text-sm font-bold text-gray-500">
            {{ $t("Logo url") }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            <FormField
              v-if="editMode"
              id="logo_url"
              name="logo_url"
              v-model="formData.logoUrl"
              :placeholder="organization.logoUrl"
            />
            <template v-else>
              <div
                v-if="isPresent(organization.logoUrl)"
                class="cursor-pointer hover:underline"
                @click="copyToClipboard(organization.logoUrl)"
              >
                <span v-if="logoUrlCopied">
                  {{ $t("Link copied to clipboard!") }}
                </span>
                <span v-else>{{ $t("Copy link to clipboard") }}</span>
              </div>
              <span v-else>{{ "—" }}</span>
            </template>
          </dd>
        </div>
      </dl>
    </div>
    <div class="p-3 flex flex-row-reverse gap-x-4">
      <GenericButton v-if="!editMode" @click="editMode = true">Modifier</GenericButton>
      <GenericButton v-if="editMode" @click="editMode = false">Annuler</GenericButton>
      <GenericButton v-if="editMode" @click="updateOrganization">Enregistrer</GenericButton>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import BackofficeService from "@/api/services/backoffice.js";
import GenericButton from "@/components/utils/GenericButton.vue";
import FieldSpan from "@/components/utils/FieldSpan.vue";
import { FormField } from "@estaly/ui";
import { isPresent } from "@/utils/validation";
import { Selector } from "@estaly/ui";

export default {
  components: {
    FieldSpan,
    FormField,
    GenericButton,
    Selector,
  },

  props: {
    organization: null,
  },

  data() {
    return {
      editMode: false,
      logoUrlCopied: false,
      formData: { ...this.organization },
      options: {
        revenueRanges: [],
        networkTypes: [],
      },
    };
  },

  watch: {
    organization: {
      handler() {
        this.formData = { ...this.organization };
      },
      deep: true,
    },
  },

  created() {
    this.fetchOptions();
  },

  computed: {
    ibanEditable() {
      return this.editMode && this.organization.invoiceLevel == "organization";
    },
  },

  methods: {
    isPresent,
    ...mapActions("notifications", ["notify"]),

    async fetchOptions() {
      const response = await BackofficeService.getOrganizationOptions();
      if (!response?.success) return;

      this.options.invoiceLevels = response.data.invoice_levels;
      this.options.revenueRanges = response.data.revenue_ranges;
      this.options.networkTypes = response.data.network_types;
    },

    copyToClipboard(text) {
      navigator.clipboard.writeText(text);
      this.toggleCopiedLogoUrlNotice();
    },

    toggleCopiedLogoUrlNotice() {
      this.logoUrlCopied = true;
      setTimeout(() => {
        this.logoUrlCopied = false;
      }, 2000);
    },

    async updateOrganization() {
      const payload = {
        name: this.formData.name,
        business_name: this.formData.businessName,
        billing_address: this.formData.billingAddress,
        total_points_of_sale: this.formData.totalPointsOfSale,
        logo_url: this.formData.logoUrl,
        invoice_level: this.formData.invoiceLevel,
        network_type: this.formData.networkType,
        revenue_range: this.formData.revenueRange,
        iban: this.formData.iban,
        registration_number: this.formData.registrationNumber,
      };
      const response = await BackofficeService.updateOrganization(this.organization.id, payload);
      if (!response?.success) return;

      this.notify({
        category: "simple",
        type: "success",
        title: "Mis à jour effectuée",
      });
      this.$emit("updated");
      this.editMode = false;
    },
  },
};
</script>
