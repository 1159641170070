<template>
  <div class="flex flex-col gap-5">
    <GeneralInformations :organization="organization" @updated="fetchOrganization" />
    <StoresInformations :stores-informations="stores" @open-modal="openStoreConfigurationModal = true" />
    <UsersInformations :organization-id="organizationId" />
    <ConfigureStoreModal
      :organization-id="organizationId"
      :open-modal="openStoreConfigurationModal"
      @updateOrganization="fetchOrganization"
      @close="openStoreConfigurationModal = false"
    />
  </div>
</template>

<script>
import BackofficeService from "@/api/services/backoffice";
import GeneralInformations from "@/views/organizations/details/GeneralInformations.vue";
import StoresInformations from "@/views/organizations/details/StoresInformations.vue";
import ConfigureStoreModal from "@/views/organizations/ConfigureStoreModal.vue";
import { mapActions } from "vuex";
import UsersInformations from "@/views/organizations/details/UsersInformations.vue";
import { toCamelCase } from "@estaly/ui/src/utils/data_formatter";

export default {
  components: {
    UsersInformations,
    StoresInformations,
    GeneralInformations,
    ConfigureStoreModal,
  },

  async beforeMount() {
    this.organizationId = this.$route.params.id;
    await this.fetchOrganization();
  },

  data() {
    return {
      organizationId: null,
      organization: null,
      openStoreConfigurationModal: false,
    };
  },

  computed: {
    stores() {
      return this.organization?.stores;
    },
  },

  methods: {
    ...mapActions("backoffice", ["withLoader"]),

    async fetchOrganization() {
      if (!this.organizationId) return;

      await this.withLoader(async () => {
        const response = await BackofficeService.getOrganization(this.organizationId);
        if (response?.success) this.organization = toCamelCase(response.data);
      });
    },
  },
};
</script>
