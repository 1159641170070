<template>
  <CollapsableContent title="Configuration technique" :open="contentOpen">
    <dl class="bg-white px-4 py-5 grid grid-cols-2 gap-x-4 gap-y-8">
      <div>
        <dt class="text-sm font-bold text-gray-500">
          {{ $t("Offer cache expiration time") }}
        </dt>
        <dd class="mt-1 text-sm text-gray-900">
          <FormField
            v-if="editMode"
            id="offer_cache_expiration_time"
            name="offer_cache_expiration_time"
            trailing-add-ons="secondes"
            v-model="offerCacheExpirationTime"
          />
          <p v-else>
            {{ offerCacheExpirationTime }}
          </p>
        </dd>
      </div>
      <div>
        <dt class="text-sm font-bold text-gray-500">
          {{ $t("Matching by categories enabled") }}
        </dt>
        <dd class="mt-1 text-sm text-gray-900">
          <Selector
            v-if="editMode"
            id="matching_by_categories_enabled"
            name="matching_by_categories_enabled"
            v-model="matchingByCategoriesEnabled"
            :options="enabledOptions"
          />
          <p v-else>
            {{ matchingByCategoriesEnabled }}
          </p>
        </dd>
      </div>
      <div>
        <dt class="text-sm font-bold text-gray-500">
          {{ $t("Record a sale") }}
        </dt>
        <dd class="mt-1 text-sm text-gray-900">
          <Selector
            v-if="editMode"
            id="record_a_sale"
            name="record_a_sale"
            v-model="recordASale"
            :options="enabledOptions"
          />
          <p v-else>
            {{ recordASale }}
          </p>
        </dd>
      </div>
      <div>
        <dt class="text-sm font-bold text-gray-500">
          {{ $t("Send matched contracts without products") }}
        </dt>
        <dd class="mt-1 text-sm text-gray-900">
          <Selector
            v-if="editMode"
            id="send_matched_contracts_without_products"
            name="send_matched_contracts_without_products"
            v-model="sendMatchedContractsWithoutProducts"
            :options="enabledOptions"
          />
          <p v-else>
            {{ sendMatchedContractsWithoutProducts }}
          </p>
        </dd>
      </div>
      <div>
        <dt class="text-sm font-bold text-gray-500">
          {{ $t("Automatic matched contract synchronization enabled") }}
        </dt>
        <dd class="mt-1 text-sm text-gray-900">
          <Selector
            v-if="editMode"
            id="automatic_matched_contract_synchronization_enabled"
            name="automatic_matched_contract_synchronization_enabled"
            v-model="automaticMatchedContractSynchronizationEnabled"
            :options="enabledOptions"
          />
          <p v-else>
            {{ automaticMatchedContractSynchronizationEnabled }}
          </p>
        </dd>
      </div>
      <div>
        <dt class="text-sm font-bold text-gray-500">
          {{ $t("Subscription form") }}
        </dt>
        <dd class="mt-1 text-sm text-gray-900">
          <Selector
            v-if="editMode"
            id="subscriptionForm"
            name="subscriptionForm"
            v-model="subscriptionForm"
            :options="subscriptionFormOptions"
          />
          <p v-else>
            {{ subscriptionForm }}
          </p>
        </dd>
      </div>
    </dl>
    <div class="m-3 flex flex-row-reverse gap-4">
      <GenericButton v-if="isStoreSetup" @click="submitEdit">Enregistrer</GenericButton>
      <GenericButton v-if="isStoreSetup" @click="cancelEdit">Annuler</GenericButton>
      <GenericButton @click="editMode = true" v-if="!editMode">Modifier</GenericButton>
      <template v-if="editMode">
        <GenericButton @click="editMode = false">Annuler</GenericButton>
        <GenericButton @click="submitEdit">Mettre à jour</GenericButton>
      </template>
    </div>
  </CollapsableContent>
</template>

<script>
import { CollapsableContent } from "@estaly/ui";
import GenericButton from "@/components/utils/GenericButton.vue";
import { FormField } from "@estaly/ui";
import { Selector } from "@estaly/ui";
import BackofficeService from "@/api/services/backoffice.js";
import { mapActions } from "vuex";

export default {
  components: {
    CollapsableContent,
    GenericButton,
    FormField,
    Selector,
  },

  props: ["store", "isStoreSetup"],

  data() {
    return {
      contentOpen: false,
      editMode: false,
      formData: { ...this.store.technicalConfiguration },
      enabledOptions: [
        { label: "true", value: "true" },
        { label: "false", value: "false" },
      ],
      subscriptionFormOptions: [
        { label: "lead", value: "lead" },
        { label: "sale", value: "sale" },
      ],
    };
  },

  computed: {
    technicalConfiguration() {
      return this.store.technicalConfiguration;
    },
    offerCacheExpirationTime: {
      get() {
        return this.technicalConfiguration.offerCacheExpirationTime;
      },
      set(value) {
        this.formData.offerCacheExpirationTime = value;
      },
    },
    matchingByCategoriesEnabled: {
      get() {
        return this.technicalConfiguration.matchingByCategoriesEnabled;
      },
      set(value) {
        this.formData.matchingByCategoriesEnabled = value;
      },
    },
    recordASale: {
      get() {
        return this.technicalConfiguration.recordASale;
      },
      set(value) {
        this.formData.recordASale = value;
      },
    },
    sendMatchedContractsWithoutProducts: {
      get() {
        return this.technicalConfiguration.sendMatchedContractsWithoutProducts;
      },
      set(value) {
        this.formData.sendMatchedContractsWithoutProducts = value;
      },
    },
    automaticMatchedContractSynchronizationEnabled: {
      get() {
        return this.technicalConfiguration.automaticMatchedContractSynchronizationEnabled;
      },
      set(value) {
        this.formData.automaticMatchedContractSynchronizationEnabled = value;
      },
    },
    subscriptionForm: {
      get() {
        return this.technicalConfiguration.subscriptionForm;
      },
      set(value) {
        this.formData.subscriptionForm = value;
      },
    },
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    cancelEdit() {
      this.editMode = false;
      this.formData = { ...this.technicalConfiguration };
    },

    async submitEdit() {
      this.editMode = false;
      const { success } = await BackofficeService.updateStore(this.store.id, this.formData);
      if (!success) return;

      await this.notify({
        category: "simple",
        type: "success",
        title: "Mise à jour effectuée",
      });
      this.$emit("storeUpdated");
    },
  },
};
</script>
