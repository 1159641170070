<template>
  <div>
    <Header />
    <div class="flex flex-row-reverse items-center">
      <div class="flex gap-x-2 justify-between items-center">
        <SecondaryButton v-if="selectedRows.length > 0" @click="markAsPaid">Marquer comme payé</SecondaryButton>
        <SecondaryButton v-else @click="exportTransfers">Exporter les virements</SecondaryButton>
      </div>
    </div>
    <ClaimInvoicesOverview v-if="amountDue > 0" class="my-5" :amount-due="amountDue" />
    <DateRangePicker
      class="mt-2"
      v-model="date"
      placeholder="Veuillez sélectionner la période de résolution de sinistre"
    />
    <SearchBar
      class="my-4"
      :placeholder="$t('Rechercher une facture de sinistre')"
      :current-page="currentPage"
      :available-filters="availableFilters"
      :selected-filters="selectedFilters"
      :initial-keywords="keywords"
      @updateFilterSearch="updateFilterSearch"
      @updateKeywordSearch="updateKeywords"
    />
    <Table
      :columns="columns"
      :data="claimInvoices"
      :isDownloadable="true"
      :isAccessible="true"
      :isSelectable="true"
      :selectableRowFilter="selectableRowFilter"
      @download="download"
      @view="rowClick"
      @sort="sortBy"
      @newSelectedRows="updateSelectedRows"
    >
      <template #refundedEntity="{ row }">
        <div class="flex">
          <div class="rounded-lg">
            <span
              :class="[
                row.refundedEntity === 'customer' ? 'bg-green-100 text-green-800' : 'bg-blue-100 text-blue-800',
                'inline-block items-center px-3 py-0.5 mt-1 rounded-full text-xs font-medium',
              ]"
            >
              {{ $t(row.refundedEntity) }}
            </span>
          </div>
        </div>
      </template>
      <template #cost="{ row }">
        <div class="flex gap-x-6">
          <div class="flex-auto">
            <div class="flex items-start gap-x-3">
              <div class="text-sm/6 font-medium text-gray-900">{{ $n(row.invoiceAmount, "currency") }}</div>
              <div
                :class="[
                  row.paid
                    ? 'bg-green-100 text-green-800 ring-green-800/10'
                    : 'bg-yellow-100 text-yellow-800 ring-yellow-800/10',
                  'rounded-md px-2 text-xs font-medium ring-1 ring-inset',
                ]"
              >
                {{ $t(`${row.paid}`) }}
              </div>
            </div>
            <div v-if="row.claimCost !== row.invoiceAmount" class="mt-1 text-xs/5 text-gray-500">
              <span class="font-bold">Coût total sinistre :</span>
              {{ $n(row.claimCost, "currency") }}
            </div>
          </div>
        </div>
      </template>
    </Table>
    <Pagination
      :currentPage="currentPage"
      :totalPages="totalPages"
      :totalCount="totalCount"
      @changePage="handleChangePage"
    />
  </div>
</template>

<script>
import BackofficeService from "@/api/services/backoffice";
import { DateRangePicker, Pagination, SecondaryButton, Table } from "@estaly/ui";
import { isPresent } from "@/utils/validation";
import { toCamelCase } from "@/utils/utils";
import SearchBar from "@/components/menu/SearchBar.vue";
import Header from "@/components/billing/Header.vue";
import ClaimInvoicesOverview from "@/components/billing/claim_invoices/ClaimInvoicesOverview.vue";

export default {
  components: {
    DateRangePicker,
    SecondaryButton,
    ClaimInvoicesOverview,
    Header,
    SearchBar,
    Pagination,
    Table,
  },
  data() {
    return {
      claimInvoices: [],
      amountDue: null,
      keywords: null,
      availableFilters: {
        organization: [],
        store: [],
        insurer: [],
        vertical: [],
        refundedEntity: [],
        paid: [],
        handlingType: [],
      },
      selectedFilters: {
        organization: [],
        store: [],
        insurer: [],
        vertical: [],
        refundedEntity: [],
        paid: [false],
        handlingType: [],
      },
      selectedRows: [],
      startDate: null,
      endDate: null,
      currentPage: 1,
      totalCount: null,
      totalPages: null,
      sortColumn: null,
      sortOrder: null,
      order: null,
      columns: [
        { label: "Sinistres", field: "friendlyId" },
        { label: "Date de résolution", field: "resolutionDate", sortable: true },
        { label: "Type", field: "handlingType" },
        { label: "Magasin", field: "store" },
        { label: "Bénéficiaire", field: "refundedEntity", slot: "refundedEntity" },
        { label: "Montant facture (payée)", slot: "cost", field: "invoiceAmount" },
      ],
    };
  },

  watch: {
    date() {
      this.handleChangePage(1);
    },
  },

  computed: {
    params() {
      return {
        page: this.currentPage,
        keywords: this.keywords,
        order: this.order,
        startDate: this.startDate,
        endDate: this.endDate,
        ...this.selectedFilters,
      };
    },

    date: {
      get() {
        return [this.startDate, this.endDate];
      },
      set(value) {
        if (value && value.length === 2) {
          this.startDate = value[0];
          this.endDate = value[1];
        } else {
          this.startDate = null;
          this.endDate = null;
        }
      },
    },
  },

  mounted() {
    this.retrieveClaimInvoices();
  },

  methods: {
    async retrieveClaimInvoices() {
      if (this.sortColumn) {
        const direction = this.sortOrder === "ASC" ? "" : "-";
        const columnEquivalent = this.sortColumn === "resolutionDate" ? "claim.resolution_date" : this.sortColumn;
        this.order = `${direction}${columnEquivalent}`;
      }

      const response = await BackofficeService.getClaimInvoices(this.params);
      if (!response?.success) return;

      const formattedResponse = toCamelCase(response.data);
      this.claimInvoices = formattedResponse.claimInvoices;
      this.amountDue = formattedResponse.amountDue;
      this.filters = formattedResponse.filters;
      this.totalCount = formattedResponse.page.totalCount;
      this.totalPages = formattedResponse.page.totalPages;
      this.availableFilters = formattedResponse.filters;
    },

    handleChangePage(newPage) {
      this.currentPage = newPage;
      this.retrieveClaimInvoices();
    },

    updateFilterSearch(filterName, filterOptions) {
      this.selectedFilters[filterName] = filterOptions;
      this.handleChangePage(1);
    },

    updateKeywords(keywords) {
      this.keywords = keywords;
      this.handleChangePage(1);
    },

    download(row) {
      window.open(row.file, "_blank");
    },

    rowClick(row) {
      window.open(`/claims/${row.friendlyId}`, "_blank");
    },

    sortBy(column) {
      if (!isPresent(this.claimInvoices)) return;
      this.sortColumn = column.field;
      this.sortOrder = this.sortOrder === "ASC" ? "DESC" : "ASC";
      this.handleChangePage(1);
    },

    updateSelectedRows(newSelectedRows) {
      this.selectedRows = newSelectedRows;
    },

    selectableRowFilter(row) {
      return row.paid === false;
    },

    async markAsPaid() {
      const claimInvoiceIds = this.selectedRows.map((row) => row.id);
      if (claimInvoiceIds.length === 0) return;
      const response = await BackofficeService.markClaimInvoicesAsPaid(claimInvoiceIds);
      if (!response?.success) return;
      await this.retrieveClaimInvoices();
    },

    async exportTransfers() {
      const response = await BackofficeService.getClaimInvoicesTransfers(this.params);
      if (!response?.success) return;

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "claim_invoices_transfers.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      this.notify({
        category: "simple",
        type: "success",
        title: "Exporting claim invoices transfers file.",
      });
    },
  },
};
</script>
