<template>
  <Modal v-bind:open="this.openModal" @close="this.closeModal">
    <div>
      <h1 class="font-bold text-lg py-4">{{ $t("Store referent informations") }}</h1>
    </div>
    <form>
      <div class="space-y-4">
        <FormField
          id="firstName"
          label="First name"
          name="first_name"
          placeholder="First Name"
          v-model="profile.firstName"
        />
        <FormField
          id="lastName"
          label="Last name"
          name="last_name"
          placeholder="Last Name"
          v-model="profile.lastName"
        />
        <FormField id="email" label="Email" name="email" placeholder="john@doe.com" v-model="profile.email" />
        <Selector
          id="profile_type"
          label="Type"
          name="profile_type"
          v-model="profile.profileType"
          :options="profileTypes"
        />
      </div>
      <div class="mt-6 flex items-center justify-end gap-x-6">
        <button type="button" class="text-sm font-semibold leading-6 text-gray-900" @click="this.closeModal">
          Annuler
        </button>
        <div
          type="submit"
          class="cursor-pointer rounded-md px-3 py-2 text-sm font-semibold text-button-font-primary bg-primary uppercase shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
          @click="configueStore"
        >
          Créer
        </div>
      </div>
    </form>
  </Modal>
</template>

<script>
import BackofficeService from "@/api/services/backoffice";
import { FormField } from "@estaly/ui";
import { Modal } from "@estaly/ui";
import { Selector } from "@estaly/ui";
import { mapActions } from "vuex";

export default {
  components: {
    Modal,
    Selector,
    FormField,
  },
  props: ["openModal", "organizationId"],

  data() {
    return {
      profile: {
        firstName: "",
        lastName: "",
        email: "",
        profileType: "",
        role: "",
      },
      profileTypes: [
        { value: "organization_manager", label: "Gérant réseau" },
        { value: "store_manager", label: "Gérant magasins" },
      ],
    };
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    closeModal: function () {
      this.$emit("close");
    },

    configueStore: async function () {
      this.profile.role = this.profile.profileType === "organization_manager" ? "" : "manager";
      const storeParams = {
        store: {
          organizationId: this.organizationId,
          source: "offline",
          status: "setup",
          setupStatus: "information_to_be_completed",
          profile: this.profile,
        },
      };
      const { success } = await BackofficeService.configureStore(storeParams);
      if (!success) return;

      this.notify({
        category: "simple",
        type: "success",
        title: "Configuration link has been sent.",
      });
      this.$emit("updateOrganization");
      this.closeModal();
    },
  },
};
</script>
