<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <HeadlessUIDialog as="div" class="relative z-30">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full lg:ml-20 max-w-3xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all relative"
            >
              <div class="absolute top-4 right-4">
                <XIcon class="w-6 h-6 cursor-pointer" @click="closeSellerUpdateModal" />
              </div>
              <VeeForm v-slot="{ handleSubmit }" :validation-schema="schema" as="div">
                <form class="space-y-4" @submit="handleSubmit($event, updateSeller)" ref="saleCreationForm">
                  <div class="grid grid-cols-2 gap-x-4 gap-y-3">
                    <div class="flex-1">
                      <label for="firstName" class="block text-sm text-gray-700">
                        Prénom
                        <span class="ml-1 text-red-600">*</span>
                      </label>
                      <Field
                        type="text"
                        name="localSeller.firstName"
                        id="firstName"
                        autocomplete="given-name"
                        class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder="John"
                        v-model="localSeller.firstName"
                      />
                      <ErrorMessage name="localSeller.firstName" class="mt-2 text-sm text-red-600" />
                    </div>
                    <div class="flex-1">
                      <label for="lastName" class="block text-sm text-gray-700">Nom</label>
                      <Field
                        type="text"
                        name="localSeller.lastName"
                        id="lastName"
                        autocomplete="family-name"
                        class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder="Doe"
                        v-model="localSeller.lastName"
                      />
                      <ErrorMessage name="localSeller.lastName" class="mt-2 text-sm text-red-600" />
                    </div>
                    <div class="flex-1">
                      <label for="email" class="block text-sm text-gray-700">
                        {{ $t("Email") }}
                      </label>
                      <div class="mt-1">
                        <Field
                          id="email"
                          name="localSeller.email"
                          type="email"
                          class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
                          v-model="localSeller.email"
                        />
                        <ErrorMessage name="localSeller.email" class="mt-2 text-sm text-red-600" />
                      </div>
                    </div>
                    <div class="flex-1">
                      <label for="phone" class="block text-sm text-gray-700">
                        {{ $t("Phone number") }}
                      </label>
                      <div class="mt-1">
                        <Field
                          id="phone"
                          name="localSeller.phone"
                          type="tel"
                          class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
                          placeholder="+33 6 99 49 75 90"
                          v-model="localSeller.phone"
                        />
                        <ErrorMessage name="localSeller.phone" class="mt-2 text-sm text-red-600" />
                      </div>
                    </div>
                  </div>
                  <div class="flex justify-center">
                    <div>
                      <button
                        class="border border-transparent text-button-font-primary bg-primary px-4 py-2 text-sm font-medium uppercase transition duration-300 transform hover:scale-105 rounded-lg focus:outline-none focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-offset-2"
                        type="submit"
                      >
                        {{ $t("Complete") }}
                      </button>
                    </div>
                  </div>
                </form>
              </VeeForm>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </HeadlessUIDialog>
  </TransitionRoot>
</template>

<script>
import BackofficeService from "@/api/services/backoffice.js";
import { isStringValid } from "@/utils/validation";
import { phoneRegex } from "@estaly/ui/src/utils/validation";

import { Form as VeeForm, Field, ErrorMessage } from "vee-validate";
import { Dialog as HeadlessUIDialog, DialogPanel, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { XIcon } from "@heroicons/vue/outline";
import * as yup from "yup";
import { mapActions } from "vuex";

export default {
  components: {
    VeeForm,
    Field,
    ErrorMessage,
    HeadlessUIDialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
    XIcon,
  },

  props: {
    isOpen: Boolean,
    selectedSeller: Object,
  },

  emits: ["getSellers", "closeSellerUpdate"],

  data: () => {
    const schema = yup.object({
      localSeller: yup.object({
        firstName: yup.string().required("Le prénom du vendeur est requis."),
        email: yup.string().nullable(),
        phone: yup
          .string()
          .nullable()
          .matches(
            phoneRegex,
            "Le numéro de téléphone n'est pas valide pour la France ou les départements d'outre-mer.",
          ),
      }),
    });

    return {
      schema,
      localSeller: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
      },
    };
  },

  watch: {
    selectedSeller: {
      handler(newValue) {
        if (newValue) {
          this.localSeller = { ...newValue };
        }
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    isFieldPresent(field) {
      return isStringValid(field);
    },

    closeSellerUpdateModal() {
      this.$emit("closeSellerUpdate");
    },

    async updateSeller() {
      const { success } = await BackofficeService.updateSeller({
        id: this.selectedSeller.id,
        ...this.localSeller,
      });
      if (!success) return;

      this.$emit("getSellers");
      this.closeSellerUpdateModal();
    },
  },
};
</script>
