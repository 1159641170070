<template>
  <div>
    <ProductListMenu
      :selected-products="selectedProducts"
      :current-page="currentPage"
      @upload="uploadProductsToPlatform"
      @remove="removeProductsFromPlatform"
      @updateKeywordSearch="updateKeywordSearch"
      @updateFilterSearch="updateFilterSearch"
      @match="matchProducts"
      @approve="approveProducts"
      @unapprove="unapproveProducts"
      @unmatch="unmatchProducts"
      @approve-match-publish="approveMatchAndPublishProducts"
      @approve-match="approveAndMatchProducts"
    />
    <button
      @click="retrieveProductsIfUserIsSignedIn(currentPage)"
      class="flex mt-10 select-none items-center gap-3 rounded-lg border py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-black-500 transition-all hover:text-primary-dark hover:opacity-75 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
      type="button"
      data-ripple-dark="true"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        aria-hidden="true"
        class="h-5 w-5"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
        ></path>
      </svg>
      <span>Refresh</span>
    </button>

    <ProductSelectionInformation
      class="mt-4"
      :selected-products="selectedProducts"
      :products="allProducts"
      @unselectAll="unselectAllProducts"
    />
    <ProductListContent
      :products="allProducts"
      :selected-products="selectedProducts"
      :all-products-selected="allProductsSelected"
      @selectProducts="handleProductSelection"
      @unselectProducts="handleProductUnselection"
    />
    <Pagination :currentPage="currentPage" v-on:changePage="handleChangePage" />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import BackofficeService from "@/api/services/backoffice";
import ProductListContent from "@/views/products/list/content/ProductListContent.vue";
import ProductSelectionInformation from "@/views/products/list/selection/ProductSelectionInformation.vue";
import ProductListMenu from "@/views/products/list/menu/ProductListMenu.vue";
import { Pagination } from "@estaly/ui";

export default {
  components: {
    ProductListContent,
    Pagination,
    ProductListMenu,
    ProductSelectionInformation,
  },
  data: () => {
    return {
      currentPage: 1,
      selectedProducts: [],
      allProductsSelected: false,
    };
  },
  watch: {
    async currentPage(newPage) {
      await this.retrieveProductsIfUserIsSignedIn(newPage);
    },
  },
  async created() {
    await this.retrieveProductsIfUserIsSignedIn(this.currentPage);
  },
  computed: {
    ...mapState("auth", ["signedIn"]),
    ...mapState("backoffice", [
      "allProducts",
      "isLoading",
      "totalNumberOfProducts",
      "totalNumberOfProductsApproved",
      "approvedProductsIds",
    ]),
  },

  methods: {
    ...mapActions("backoffice", ["getProducts", "updateFilter", "updateSearch"]),
    ...mapActions("notifications", ["notify"]),
    async retrieveProductsIfUserIsSignedIn(page) {
      if (this.signedIn) {
        await this.getProducts({
          page: page,
          parents: true,
        });
      } else {
        this.$router.push("/signin");
      }
    },
    handleChangePage(page) {
      this.currentPage = page;
    },
    handleProductSelection(products) {
      const productsToAdd = products
        .filter((product) => !this.selectedProducts.includes(product.id))
        .map((product) => product.id);
      this.selectedProducts = this.selectedProducts.concat(productsToAdd);
    },
    handleProductUnselection(products) {
      const productsRefIds = products.map((product) => product.id);
      this.selectedProducts = this.selectedProducts.filter((refId) => !productsRefIds.includes(refId));
      this.allProductsSelected = false;
    },
    unselectAllProducts() {
      this.selectedProducts = [];
      this.allProductsSelected = false;
    },
    updateKeywordSearch(keywords) {
      const newPage = 1;
      this.currentPage = newPage;
      this.unselectAllProducts();
      this.updateSearch({
        searchString: keywords,
        page: newPage,
        parents: true,
      });
    },
    updateFilterSearch(filterName, filterOptions) {
      const newPage = 1;
      this.currentPage = newPage;
      this.unselectAllProducts();
      this.updateFilter({
        filterName: filterName,
        filterOptions: filterOptions,
        page: 1,
        parents: true,
      });
    },
    uploadProductsToPlatform() {
      this.updateMerchantProducts(true);
    },
    async approveProducts() {
      const { success } = await BackofficeService.approveProducts(
        this.selectedProducts,
        this.allProductsSelected,
        this.approvedProductsIds,
      );
      if (!success) return;

      this.notify({
        category: "simple",
        type: "success",
        title: "Products were successfully approved on your store.",
      });
      this.unselectAllProducts();
      await this.retrieveProductsIfUserIsSignedIn(this.currentPage);
    },
    async unapproveProducts() {
      const { success } = await BackofficeService.unapproveProducts(
        this.selectedProducts,
        this.allProductsSelected,
        this.approvedProductsIds,
      );
      if (!success) return;

      this.notify({
        category: "simple",
        type: "success",
        title: "Products were successfully unapproved on your store.",
      });
      this.unselectAllProducts();
      await this.retrieveProductsIfUserIsSignedIn(this.currentPage);
    },
    async unmatchProducts() {
      const { success } = await BackofficeService.unmatchProducts(
        this.selectedProducts,
        this.allProductsSelected,
        this.approvedProductsIds,
      );
      if (!success) return;

      this.notify({
        category: "simple",
        type: "success",
        title: "Products were successfully unapproved.",
      });
      this.unselectAllProducts();
      await this.retrieveProductsIfUserIsSignedIn(this.currentPage);
    },
    async approveMatchAndPublishProducts() {
      const { success } = await BackofficeService.approveMatchPublish(
        this.selectedProducts,
        this.allProductsSelected,
        this.approvedProductsIds,
      );
      if (!success) return;

      this.notify({
        category: "simple",
        type: "success",
        title: "Products were successfully unpublished from your store.",
      });
      this.unselectAllProducts();
      await this.retrieveProductsIfUserIsSignedIn(this.currentPage);
    },
    async approveAndMatchProducts() {
      const { success } = await BackofficeService.approveMatch(
        this.selectedProducts,
        this.allProductsSelected,
        this.approvedProductsIds,
      );
      if (!success) return;

      this.notify({
        category: "simple",
        type: "success",
        title: "Products were successfully unpublished from your store.",
      });
      this.unselectAllProducts();
      await this.retrieveProductsIfUserIsSignedIn(this.currentPage);
    },
    async matchProducts() {
      const { success } = await BackofficeService.matchProducts(
        this.selectedProducts,
        this.allProductsSelected,
        this.approvedProductsIds,
      );
      if (!success) return;

      this.notify({
        category: "simple",
        type: "success",
        title: "Products were successfully matched on your store.",
        text: "Please publish these products to make them available for offers",
      });
      this.unselectAllProducts();
      await this.retrieveProductsIfUserIsSignedIn(this.currentPage);
    },
    removeProductsFromPlatform() {
      this.updateMerchantProducts(false);
    },
    async updateMerchantProducts(enableProducts) {
      const { success } = await BackofficeService.productPlatformUpdate(
        enableProducts,
        this.selectedProducts,
        this.allProductsSelected,
        this.approvedProductsIds,
      );
      if (!success) return;

      this.notify({
        category: "simple",
        type: "success",
        title: "Plans were successfully updated on your store.",
        text: "They will be available for sale on your store very shortly.",
      });
      this.unselectAllProducts();
      await this.retrieveProductsIfUserIsSignedIn(this.currentPage);
    },
  },
};
</script>
