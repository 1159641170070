import AuthService from "../../api/services/auth";
import UserService from "../../api/services/user";

export default {
  namespaced: true,
  state: {
    currentUser: {},
    signedIn: false,
    csrf: null,
  },

  getters: {
    isEstalyManager: () => true, // TODO : to change (state) => state.currentUser.role === "estalymanager",
  },

  mutations: {
    setCurrentUser(state, { currentUser, csrf }) {
      state.currentUser = currentUser;
      state.signedIn = true;
      state.csrf = csrf;
    },

    unsetCurrentUser(state) {
      state.currentUser = {};
      state.signedIn = false;
      state.csrf = null;
    },

    refresh(state, csrf) {
      state.csrf = csrf;
      state.signedIn = true;
    },
  },

  actions: {
    async signin({ dispatch }, user) {
      const response = await AuthService.signin(user);
      if (!response?.success) return response;

      await dispatch("postSignIn", response);
      return response;
    },

    async signup({ dispatch }, params) {
      const response = await AuthService.signup(params);
      if (!response?.success) return response;

      await dispatch("postSignIn", response);
      return response;
    },

    async resetPassword({ dispatch }, { resetPasswordToken, payload }) {
      const response = await AuthService.resetPassword(resetPasswordToken, payload);
      if (!response?.success) return response;

      await dispatch("postSignIn", response);
      return response;
    },

    async postSignIn(context, response) {
      const csrf = response.data.csrf;
      if (csrf) {
        const resp = await UserService.getUser();
        const currentUser = resp.data;
        context.commit("setCurrentUser", { currentUser, csrf });
        context.dispatch("notifications/clearAll", null, { root: true });
      } else {
        context.commit("unsetCurrentUser");
      }
    },

    async signout({ commit }) {
      const response = await AuthService.signout();
      if (!response?.success) return response;

      commit("unsetCurrentUser");
      localStorage.clear();
      return response;
    },
  },
};
