<template>
  <div class="bg-white shadow sm:rounded-lg">
    <div class="px-4 py-5 sm:p-6">
      <h3 class="text-base font-semibold text-gray-900">Récapitulatif</h3>
      <div class="mt-2 max-w-xl text-sm text-gray-500">
        <p>Le montant restant à payer s'élève à {{ $n(amountDue, "currency") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["amountDue"],
};
</script>
