<template>
  <div>
    <SearchBar
      :current-page="currentPage"
      :placeholder="'Recherche de point de vente par nom/url'"
      :initial-keywords="keywords"
      :available-filters="availableFilters"
      :selected-filters="selectedFilters"
      @updateKeywordSearch="updateKeywordSearch"
      @updateFilterSearch="updateFilterSearch"
    />
    <div class="mt-10 relative overflow-x-auto">
      <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr class="hidden md:table-row">
            <th scope="col" class="px-6 py-3">Id</th>
            <th scope="col" class="px-6 py-3">Nom</th>
            <th scope="col" class="px-6 py-3">Organization</th>
            <th scope="col" class="px-6 py-3">Statut</th>
            <th scope="col" class="px-6 py-3"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="store in stores"
            :key="store.id"
            @click="redirectToStoreDetailsPage(store.id)"
            class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 cursor-pointer hover:bg-gray-200"
          >
            <td class="px-6 py-4 hidden md:table-cell">
              {{ store.id }}
            </td>
            <td class="px-6 py-4 hidden md:table-cell">
              <span v-if="isPresent(store.salesChannel)">{{ store.salesChannel }}</span>
              <span v-else-if="isPresent(store.setupBy)" class="text-gray-400">Référent : {{ store.setupBy }}</span>
            </td>
            <td class="px-6 py-4 hidden md:table-cell">
              {{ store.organizationName }}
            </td>
            <td class="px-6 py-4 hidden md:table-cell">
              <FieldSpan
                :style-to-add="getStoreStatusFieldDisplayStyle(storeStatusOrSetup(store))"
                :value="storeStatusOrSetup(store)"
              />
            </td>
            <td class="px-6 py-4 hidden md:table-cell">
              <TrashIcon
                class="h-6 w-6"
                aria-hidden="true"
                v-if="store.status === 'setup'"
                @click.stop="openDisableStoreModal(store.id)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <Pagination
      :currentPage="currentPage"
      :totalPages="totalPages"
      :totalCount="totalCount"
      @changePage="handleChangePage"
    />
    <DisableModal
      :open="disableStoreModalOpen"
      :storeId="disableStoreId"
      @closeDisableStoreModal="disableStoreModalOpen = false"
      @disableStore="disableStore"
    />
  </div>
</template>
<script>
import BackofficeService from "@/api/services/backoffice";
import SearchBar from "@/components/menu/SearchBar.vue";
import FieldSpan from "@/components/utils/FieldSpan.vue";
import DisableModal from "@/components/store/DisableModal.vue";
import { TrashIcon } from "@heroicons/vue/solid";
import { mapActions, mapState } from "vuex";
import { isPresent } from "@/utils/validation";
import { Pagination } from "@estaly/ui";

export default {
  components: {
    Pagination,
    SearchBar,
    FieldSpan,
    DisableModal,
    TrashIcon,
  },

  async beforeMount() {
    this.loadQueryParams();
    await this.retrieveStores();
  },

  data() {
    return {
      stores: [],
      currentPage: 1,
      keywords: null,
      availableFilters: {
        status: [],
      },
      selectedFilters: {
        status: [],
      },
      totalCount: null,
      totalPages: null,
      disableStoreModalOpen: false,
      disableStoreId: null,
    };
  },

  computed: {
    ...mapState("backoffice", ["isLoading"]),
    status: {
      get() {
        return this.selectedFilters.status;
      },
      set(value) {
        if (!isPresent(value)) return (this.selectedFilters.status = []);

        this.selectedFilters.status = Array.isArray(value) ? value : [value];
      },
    },

    searchParams() {
      return {
        ...this.$route.query,
        page: this.$route.query.page ? parseInt(this.$route.query.page, 10) : this.currentPage,
        status: this.status,
      };
    },
  },

  methods: {
    ...mapActions("notifications", ["notify"]),
    ...mapActions("backoffice", ["withLoader"]),
    isPresent,

    async retrieveStores() {
      const response = await BackofficeService.getStores(this.searchParams);
      this.stores = response.data.stores;
      this.availableFilters = response.data.filters;
      this.totalCount = response.data.page.totalCount;
      this.totalPages = response.data.page.totalPages;
    },

    updateRouteQueryParams() {
      const query = {};
      if (this.currentPage) query.page = this.currentPage;
      if (this.keywords) query.keywords = this.keywords;
      if (this.status) query.status = this.status;
      this.$router.push({ query });
    },

    loadQueryParams() {
      this.currentPage = this.$route.query.page ? parseInt(this.$route.query.page) : 1;
      this.keywords = this.$route.query.keywords;
      this.status = this.$route.query.status;
    },

    handleChangePage(page) {
      this.currentPage = page;
      this.updateRouteQueryParams();
    },

    redirectToStoreDetailsPage(storeId) {
      this.$router.push(`/stores/${storeId}`);
    },

    updateKeywordSearch(keywords) {
      this.keywords = keywords;
      this.currentPage = 1;
      this.updateRouteQueryParams();
    },

    updateFilterSearch(filterName, filterOptions) {
      this.selectedFilters[filterName] = filterOptions;
      this.currentPage = 1;
      this.updateRouteQueryParams();
    },

    getStoreStatusFieldDisplayStyle(status) {
      switch (status) {
        case "active":
          return "bg-green-100 text-green-800";
        case "inactive":
          return "bg-red-100 text-red-800";
        case "pending_verification":
          return "bg-blue-100 text-blue-800";
        case "information_to_be_completed":
          return "bg-yellow-100 text-yellow-800";
        default:
          return "bg-yellow-100 text-yellow-800";
      }
    },

    storeStatusOrSetup(store) {
      if (store.status == "active" || store.status == "inactive") return store.status;

      return store.setupStatus;
    },

    openDisableStoreModal(storeId) {
      this.disableStoreId = storeId;
      this.disableStoreModalOpen = true;
    },

    async disableStore(storeId) {
      this.disableStoreModalOpen = false;

      await this.withLoader(async () => {
        const { success } = await BackofficeService.updateStoreStatus(storeId, "inactive");
        if (success) {
          await this.notify({
            category: "simple",
            type: "success",
            title: "Mise à jour effectuée",
          });
        }
      });

      await this.retrieveStores();
    },
  },

  watch: {
    "$route.query"() {
      this.retrieveStores();
    },
  },
};
</script>
